import { QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  Icon,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
  useTheme,
  useToken,
} from "@chakra-ui/react";
import axios from "axios";
import {
  FixedPlaidLinkOnSuccessMetadata,
  PaymentMethod,
  useFormControl,
} from "donation-form/src/components/form/useFormControl";
import * as React from "react";
import { useEffect, useState } from "react";
import { VscLock } from "react-icons/vsc";
import { usePlaidLink } from "react-plaid-link";

export function PaymentPlaid() {
  const theme = useTheme();
  const form = useFormControl();
  const [plaidLinkToken, setPlaidLinkToken] = useState<string | null>(null);

  const { open: openPlaidPopup, ready: isPlaidLinkReady } = usePlaidLink({
    token: plaidLinkToken,
    onSuccess: (publicToken, metadata) => {
      const resFixed = metadata as FixedPlaidLinkOnSuccessMetadata;
      const accountsFiltered = resFixed.accounts.filter(account => ["checking", "savings"].includes(account.subtype));
      const isPlaidChargeable = accountsFiltered.length !== 0;
      if (isPlaidChargeable) {
        form.setPaymentMethod(PaymentMethod.Plaid);
        form.setPlaidAccountId(accountsFiltered[0].id);
      } else if (form.isSpendingRule()) {
        form.setPaymentMethod(PaymentMethod.CreditCard);
      }
      form.setPlaidAccountsAvailable(accountsFiltered);
      form.setPlaidDetailsRequest({
        public_token: resFixed.public_token,
        spending_account_ids: resFixed.accounts.map(a => a.id),
      });
    },
  });

  useEffect(() => {
    async function createPlaidLinkToken() {
      // todo handle auth error
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL_V2}plaid/create-link-token`,
      );
      setPlaidLinkToken(response.data.token);
    }
    createPlaidLinkToken();
  }, []);

  return (
    <Box>
      <Collapse in={!form.isPlaidConnected()}>
        {form.isSpendingRule() && (
          <Center>
            <Box mt={theme.space.md} textAlign="center">
              <Flex mx="auto" justifyContent="center">
                <Icon mx="auto" w="80px" h="80px" as={VscLock} />
              </Flex>
              <Text mt={theme.space.md} fontWeight="bold">
                Your donations are tracked automatically with your bank
              </Text>
              <Text mt={theme.space.md} maxW="500px" lineHeight={1.3}>
                In order for Momentum to automatically process a donation every time{" "}
                {form.formRule.rule.trigger_lower}, you need to sign into the bank that holds
                your primary debit or credit card.
                <Tooltip
                  label="Momentum will not view or store your login information - linking your bank account is handled securely by Plaid, the industry standard for third-party bank integration."
                  placement="bottom"
                >
                  <Icon
                    ml={theme.space.sm}
                    w="15px"
                    h="15px"
                    as={QuestionOutlineIcon}
                    color="gray.500"
                  />
                </Tooltip>
              </Text>
            </Box>
          </Center>
        )}
        {!form.isSpendingRule() && (
          <Center>
            <Box mt={theme.space.lg} textAlign="center">
              <Text>
                Paying with ACH reduces the payment processing fee by 2%. Link your bank
                below to set up ACH.
              </Text>
            </Box>
          </Center>
        )}
        <Flex mt={theme.space.md} justify="center" color="gray.500" fontSize="sm">
          <Link href="https://plaid.com" target="_blank" d="flex">
            <Box>Processed securely with</Box>
            <Box d="inline-block" ml={theme.space.sm} mt="3px" w="50px">
              <PlaidLogo />
            </Box>
          </Link>
        </Flex>
        <Center>
          <Button
            onClick={() => openPlaidPopup()}
            isLoading={!isPlaidLinkReady}
            mt={theme.space.lg}
            size="lg"
          >
            Connect
          </Button>
        </Center>
      </Collapse>

      <Collapse
        in={form.isPlaidPay() && form.isPlaidConnected() && !form.isPlaidChargeable()}
      >
        <Text mt={theme.space.md}>
          Your bank account was successfully authorized, but unfortunately it doesn't support
          ACH payments. Please{" "}
          <Link onClick={() => openPlaidPopup()} color="brand.500">
            connect
          </Link>{" "}
          another bank your use a{" "}
          <Button
            variant="link"
            onClick={() => form.setPaymentMethod(PaymentMethod.CreditCard)}
          >
            credit card
          </Button>{" "}
          instead.
        </Text>
      </Collapse>

      <Collapse
        in={form.isPlaidPay() && form.isPlaidConnected() && form.isPlaidChargeable()}
      >
        <Box>
          <Box mt={theme.space.md}>Pay with:</Box>

          <RadioGroup
            value={form.plaidAccountId ?? "null"}
            onChange={valueNew => {
              form.setPlaidAccountId(valueNew);
            }}
            mt={theme.space.sm}
          >
            <Stack>
              {form.plaidAccountsAvailable?.map(account => (
                <Radio value={account.id} key={account.id}>
                  <Text mt="-3px">
                    {account.subtype} account № **** {account.mask}
                  </Text>
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </Box>
      </Collapse>
    </Box>
  );
}

function PlaidLogo() {
  const [gray400] = useToken("colors", [`gray.500`]);
  return (
    <svg viewBox="0 0 126 48" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path id="a" d="M0 47.473h126V0H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M66.248 16.268c-1.057-.889-2.861-1.333-5.413-1.333h-5.756v17.788h4.304v-5.575h1.928c2.34 0 4.056-.515 5.148-1.546 1.23-1.155 1.849-2.693 1.849-4.613 0-1.991-.687-3.565-2.06-4.721m-5.044 6.855h-1.821V18.96h1.636c1.99 0 2.985.698 2.985 2.094 0 1.378-.934 2.068-2.8 2.068M75.673 14.934h-4.488v17.788h9.69v-4.026h-5.202zM89.668 14.934l-7.05 17.788h4.832l.924-2.586H94.5l.845 2.586h4.886l-7-17.788h-3.563zm-.053 11.601l1.849-6.08 1.82 6.08h-3.67z"
          fill={gray400}
        />
        <mask id="b" fill="#fff">
          <path id="a2" d="M0 47.473h126V0H0z" />
        </mask>
        <path
          fill={gray400}
          mask="url(#b)"
          d="M102.473 32.722h4.489V14.934h-4.489zM124.39 18.268a7.376 7.376 0 0 0-2.14-2.053c-1.355-.854-3.204-1.28-5.545-1.28h-5.914v17.787h6.918c2.5 0 4.506-.817 6.02-2.453 1.514-1.635 2.27-3.805 2.27-6.508 0-2.15-.537-3.981-1.61-5.493m-7.182 10.427h-1.927v-9.734h1.954c1.373 0 2.428.43 3.168 1.287.74.857 1.11 2.073 1.11 3.647 0 3.2-1.435 4.8-4.305 4.8M18.637 0L4.09 3.81.081 18.439l5.014 5.148L0 28.65l3.773 14.693 14.484 4.047 5.096-5.064 5.014 5.147 14.547-3.81 4.008-14.63-5.013-5.146 5.095-5.063L43.231 4.13 28.745.083l-5.094 5.063L18.637 0zM9.71 6.624l7.663-2.008 3.351 3.44-4.887 4.856L9.71 6.624zm16.822 1.478l3.405-3.383 7.63 2.132-6.227 6.187-4.808-4.936zM4.672 17.238l2.111-7.705 6.125 6.288-4.886 4.856-3.35-3.44zm29.547-1.243l6.227-6.189 1.986 7.74-3.404 3.384-4.809-4.935zm-15.502-.127l4.887-4.856 4.807 4.936-4.886 4.856-4.808-4.936zm-7.814 7.765l4.886-4.856 4.81 4.936-4.888 4.856-4.808-4.936zm15.503.127l4.886-4.856L36.1 23.84l-4.887 4.856-4.807-4.936zM4.57 29.927l3.406-3.385 4.807 4.937-6.225 6.186-1.988-7.738zm14.021 1.598l4.887-4.856 4.808 4.936-4.886 4.856-4.809-4.936zm15.502.128l4.887-4.856 3.351 3.439-2.11 7.705-6.128-6.288zm-24.656 8.97l6.226-6.189 4.81 4.936-3.406 3.385-7.63-2.133zm16.843-1.206l4.886-4.856 6.126 6.289-7.662 2.007-3.35-3.44z"
        />
      </g>
    </svg>
  );
}
