import { useTheme } from "@chakra-ui/react";

export function MomentumLogo(props: {
  width?: number;
  height?: number;
  color?: string | null;
}) {
  const theme = useTheme();
  const color = props.color ?? theme.colors.brand.momentumPrimary;
  return (
    <svg
      width={props.width ?? "328"}
      height={props.height ?? "70"}
      viewBox="0 0 358 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M151.998 48.912C152.824 48.912 153.238 49.311 153.238 50.105V52.207C153.238 53.036 152.824 53.449 151.998 53.449H142.193C141.398 53.449 141 53.036 141 52.207V50.105C141 49.311 141.398 48.912 142.193 48.912H144.582L142.51 23.069L135.26 49.414C135.002 50.385 134.358 50.871 133.326 50.871H130.089C129.092 50.871 128.446 50.377 128.156 49.391L120.85 22.937L118.576 48.912H121.584C122.38 48.912 122.777 49.311 122.777 50.105V52.207C122.777 53.036 122.38 53.449 121.584 53.449H110.967C110.139 53.449 109.725 53.036 109.725 52.207V50.105C109.725 49.311 110.139 48.912 110.967 48.912H113.832L116.088 23.128H113.272C112.475 23.128 112.078 22.73 112.078 21.934V19.833C112.078 19.007 112.475 18.592 113.272 18.592H122.874C123.901 18.592 124.526 19.067 124.751 20.016L131.735 45.844L138.64 20.032C138.862 19.073 139.481 18.592 140.496 18.592H149.882C150.709 18.592 151.124 19.007 151.124 19.833V21.934C151.124 22.73 150.709 23.128 149.882 23.128H147.065L149.133 48.912H151.998Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.5 31.961C159.527 31.961 156.042 35.623 156.042 42.943C156.042 50.266 159.527 53.927 166.5 53.927C173.471 53.927 176.956 50.266 176.956 42.943C176.956 35.623 173.471 31.961 166.5 31.961ZM166.5 49.484C162.903 49.484 161.104 47.306 161.104 42.943C161.104 38.583 162.902 36.402 166.5 36.402C170.097 36.402 171.895 38.583 171.895 42.943C171.895 47.306 170.097 49.484 166.5 49.484Z"
        fill={color}
      />
      <path
        d="M219.118 49.247C219.945 49.247 220.359 49.645 220.359 50.439V52.207C220.359 53.036 219.945 53.449 219.118 53.449H213.531C212.131 53.449 211.43 52.781 211.43 51.443V38.933C211.43 38.137 211.127 37.516 210.523 37.07C209.919 36.624 209.074 36.402 207.994 36.402C206.37 36.402 204.747 36.911 203.121 37.929V38.12V49.246H205.652C206.448 49.246 206.847 49.644 206.847 50.438V52.206C206.847 53.035 206.448 53.448 205.652 53.448H199.97C198.601 53.448 197.917 52.78 197.917 51.442V38.932C197.917 38.136 197.653 37.515 197.129 37.069C196.604 36.623 195.863 36.401 194.908 36.401C193.157 36.401 191.376 37.101 189.56 38.502V49.246H191.996C192.823 49.246 193.237 49.644 193.237 50.438V52.206C193.237 53.035 192.823 53.448 191.996 53.448H181.872C181.044 53.448 180.632 53.035 180.632 52.206V50.438C180.632 49.644 181.044 49.246 181.872 49.246H184.354V36.64H181.872C181.044 36.64 180.632 36.227 180.632 35.399V33.68C180.632 32.853 181.044 32.438 181.872 32.438H187.269C188.636 32.438 189.321 33.091 189.321 34.396V35.303C191.486 33.076 193.953 31.961 196.722 31.961C199.523 31.961 201.417 32.933 202.405 34.873C204.538 32.933 207.02 31.961 209.853 31.961C212.018 31.961 213.698 32.503 214.892 33.584C216.084 34.667 216.682 36.181 216.682 38.121V49.247H219.118V49.247Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.389 34.491C237.812 32.805 235.481 31.961 232.393 31.961C229.209 31.961 226.742 32.893 224.993 34.754C223.241 36.617 222.365 39.347 222.365 42.943C222.365 46.572 223.304 49.31 225.181 51.156C227.06 53.003 229.783 53.927 233.347 53.927C235.861 53.927 238.09 53.385 240.033 52.302C240.765 51.857 240.941 51.236 240.558 50.439L239.89 49.15C239.442 48.324 238.853 48.084 238.122 48.435C236.626 49.135 235.145 49.484 233.681 49.484C230.053 49.484 228 47.895 227.523 44.709H239.985C240.525 44.709 240.955 44.561 241.274 44.258C241.593 43.955 241.752 43.534 241.752 42.992V41.798C241.752 38.615 240.965 36.18 239.389 34.491ZM237.121 41.272H227.428C227.617 39.522 228.135 38.265 228.979 37.5C229.822 36.736 231.009 36.354 232.537 36.354C235.593 36.354 237.121 37.994 237.121 41.272Z"
        fill={color}
      />
      <path
        d="M271.785 49.247C272.613 49.247 273.027 49.645 273.027 50.439V52.207C273.027 53.036 272.613 53.449 271.785 53.449H266.103C264.734 53.449 264.05 52.781 264.05 51.443V39.936C264.05 37.579 262.888 36.402 260.565 36.402C258.495 36.402 256.459 37.15 254.452 38.646V49.247H256.888C257.715 49.247 258.13 49.645 258.13 50.439V52.207C258.13 53.036 257.715 53.449 256.888 53.449H246.766C245.938 53.449 245.524 53.036 245.524 52.207V50.439C245.524 49.645 245.938 49.247 246.766 49.247H249.248V36.64H246.766C245.938 36.64 245.524 36.227 245.524 35.399V33.68C245.524 32.853 245.938 32.438 246.766 32.438H252.209C253.578 32.438 254.262 33.091 254.262 34.396V35.352C256.426 33.092 259.053 31.961 262.141 31.961C264.369 31.961 266.113 32.606 267.369 33.895C268.627 35.183 269.257 36.958 269.257 39.219V49.246H271.785V49.247Z"
        fill={color}
      />
      <path
        d="M288.354 35.399C288.354 36.227 287.941 36.64 287.113 36.64H282.625L282.578 46.954C282.578 48.642 283.486 49.436 285.299 49.341L286.588 49.246C286.971 49.215 287.274 49.31 287.495 49.532C287.719 49.755 287.829 50.073 287.829 50.486V52.397C287.829 53.225 287.448 53.655 286.683 53.686C285.632 53.846 284.566 53.926 283.486 53.926C279.472 53.926 277.467 51.856 277.467 47.717V36.64H275.081C274.251 36.64 273.839 36.227 273.839 35.399V33.68C273.839 32.853 274.251 32.438 275.081 32.438H277.467L278.422 26.708C278.581 25.881 279.074 25.467 279.902 25.467H281.381C282.177 25.467 282.576 25.881 282.576 26.708V32.438H287.111C287.939 32.438 288.352 32.853 288.352 33.68V35.399H288.354Z"
        fill={color}
      />
      <path
        d="M290.265 33.824C290.265 32.901 290.727 32.438 291.649 32.438H297.045C298.445 32.438 299.147 33.107 299.147 34.444V46.478C299.147 48.483 300.198 49.484 302.297 49.484C304.08 49.484 305.623 48.738 306.93 47.242V33.824C306.93 32.901 307.375 32.438 308.267 32.438H310.748C311.642 32.438 312.086 32.901 312.086 33.824V47.097C312.086 47.766 312.293 48.275 312.707 48.626C313.12 48.976 313.645 49.118 314.282 49.056C315.142 48.993 315.571 49.374 315.571 50.202V52.017C315.571 52.812 315.236 53.322 314.569 53.543C314.06 53.798 313.408 53.927 312.611 53.927C311.369 53.927 310.326 53.615 309.483 52.995C308.64 52.374 308.153 51.507 308.027 50.391C306.53 52.748 304.255 53.926 301.199 53.926C298.906 53.926 297.123 53.313 295.85 52.086C294.576 50.861 293.941 49.117 293.941 46.858V36.64H291.648C290.726 36.64 290.264 36.195 290.264 35.303V33.824H290.265Z"
        fill={color}
      />
      <path
        d="M356.731 49.247C357.559 49.247 357.972 49.645 357.972 50.439V52.207C357.972 53.036 357.559 53.449 356.731 53.449H351.144C349.742 53.449 349.042 52.781 349.042 51.443V38.933C349.042 38.137 348.739 37.516 348.136 37.07C347.531 36.624 346.687 36.402 345.607 36.402C343.981 36.402 342.358 36.911 340.735 37.929V38.12V49.246H343.265C344.062 49.246 344.459 49.644 344.459 50.438V52.206C344.459 53.035 344.062 53.448 343.265 53.448H337.583C336.213 53.448 335.529 52.78 335.529 51.442V38.932C335.529 38.136 335.266 37.515 334.741 37.069C334.216 36.623 333.475 36.401 332.52 36.401C330.77 36.401 328.987 37.101 327.172 38.502V49.246H329.608C330.434 49.246 330.85 49.644 330.85 50.438V52.206C330.85 53.035 330.434 53.448 329.608 53.448H319.485C318.658 53.448 318.243 53.035 318.243 52.206V50.438C318.243 49.644 318.658 49.246 319.485 49.246H321.967V36.64H319.485C318.658 36.64 318.243 36.227 318.243 35.399V33.68C318.243 32.853 318.658 32.438 319.485 32.438H324.881C326.25 32.438 326.934 33.091 326.934 34.396V35.303C329.1 33.076 331.567 31.961 334.336 31.961C337.136 31.961 339.031 32.933 340.018 34.873C342.151 32.933 344.633 31.961 347.467 31.961C349.63 31.961 351.31 32.503 352.503 33.584C353.697 34.667 354.295 36.181 354.295 38.121V49.247H356.731V49.247Z"
        fill={color}
      />
      <path
        d="M82.469 59.917C76.125 59.665 69.6 55.78 64.096 48.975L57.112 56.037C64.309 64.563 73.141 69.438 82.079 69.791C82.147 69.793 82.212 69.794 82.278 69.794C84.917 69.794 87.106 67.709 87.212 65.049C87.318 62.321 85.195 60.024 82.469 59.917Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M72.35 9.742C69.85 3.708 64.963 0.247002 58.942 0.247002C51.53 0.247002 46.121 5.943 44.473 15.481C42.821 25.051 45.2 36.434 50.63 46.468C54.563 43.555 57.332 37.628 57.337 37.616C54.38 30.65 53.154 23.282 54.211 17.163C54.494 15.513 55.68 10.129 58.941 10.129C60.362 10.129 61.981 10.532 63.22 13.523C66.583 21.643 63.017 39.234 54.062 48.19C44.191 58.064 38.148 41.032 35.802 30.488C35.274 28.094 33.067 26.455 30.626 26.628C28.181 26.803 26.232 28.747 26.05 31.191C26.042 31.28 25.337 40.219 21.562 48.207C17.797 56.166 12.656 60.035 5.842 60.035C3.114 60.035 0.902 62.248 0.902 64.975C0.902 67.704 3.115 69.917 5.842 69.917C16.561 69.917 25.085 63.869 30.494 52.429C31.061 51.229 31.571 50.019 32.029 48.818C35.544 55.498 39.728 59.579 44.507 60.972C48.301 62.083 54.251 61.975 61.05 55.178C72.196 44.031 77.584 22.375 72.35 9.742Z"
        fill="url(#paint1_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="102.661"
          y1="63.4665"
          x2="88.4309"
          y2="33.387"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.245" stopColor="#FBC75F" />
          <stop offset="0.4032" stopColor="#FAC45F" />
          <stop offset="0.536" stopColor="#F9B95D" />
          <stop offset="0.6595" stopColor="#F6A85C" />
          <stop offset="0.7775" stopColor="#F38F59" />
          <stop offset="0.8905" stopColor="#EE7055" />
          <stop offset="1" stopColor="#E84A51" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="0.9023"
          y1="69.9173"
          x2="74.3638"
          y2="69.9173"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0997" stopColor="#D83445" />
          <stop offset="0.4013" stopColor="#EE6F55" />
          <stop offset="1" stopColor="#FBC75F" />
        </linearGradient>
      </defs>
    </svg>
  );
}
