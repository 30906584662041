import axios from "axios";
import { FormStep } from "donation-form/src/components/form/useFormControl";
import { GetServerSidePropsContext } from "next";

export const RuleKinds = ["personal", "monthly", "once"] as const;
export type RuleKindDeprecated = typeof RuleKinds[number];
export type RuleKind = "event_based" | "monthly" | "once" | "schedule" | "spending";

export type PrimaryKey = number;

export type RuleType = "MANUAL" | "SPENDING" | "SCHEDULE";

export type URL = string;

export interface DonationFormConfig extends DonationFormConfigRaw {
  rules: Array<FormRule>;
  progress_bar_raised: number;
  is_hide_footer?: boolean;
  step_current?: FormStep;
  form_rule_current?: FormRule;
  is_widget_mode?: boolean;

  is_org_pardes?: boolean;
}

export interface DonationFormConfigRaw {
  id: PrimaryKey;
  unique_id: string;
  title: string;
  name: string;
  description: string;
  header_logo: URL;
  header_logo_url: string;
  header_logo_width: number;
  favicon: URL;
  primary_color: string;
  background_image: URL | null;
  background_color: string | null;
  font_url?: string;
  font_family?: string;
  background_color_opacity?: number;
  is_dark_mode_enabled_for_header_and_footer: boolean;
  seo_title: string | "";
  seo_description: string | "";
  seo_image: URL | null;
  domain: string | null;
  slug: string;
  is_using_tabs_for_rule_switching: boolean;

  is_preview_mode?: boolean;
  is_embedded_mode?: boolean;

  charity: {
    id: PrimaryKey;
    name: string;
    url: string;
    ein: string;
  };
  is_enable_latest_donors_board: boolean;
  is_enable_leaderboard: boolean;
  is_enable_ach: boolean;

  is_US_political_campaign_mode: boolean;
  is_phone_number_field_visible: boolean;
  is_enable_comment_field: boolean;
  is_address_required: boolean;
  is_enable_salutation_field: boolean;
  is_enable_tip: boolean;
  is_enable_fees_covering_field: boolean;
  is_enable_anonymous_donation_field: boolean;
  is_enable_donation_dedication: boolean;
  is_enable_newsletter_subscription_field: boolean;
  is_enable_donation_on_behalf_of: boolean;

  comment_field_label: string;

  is_progress_bar_enabled: boolean;
  progress_bar_label_start_postfix: string;
  progress_bar_offset: number | null;
  progress_bar_target: number | null;

  segment_id?: string;
  google_analytics_id?: string;

  is_earmarkings_visible: boolean;
  earmarkings: Array<RuleEarmarking>;
  rules: Array<FormRule>;
}

export interface FormRuleAmount {
  id: PrimaryKey;
  amount: number;
  label_override?: string;
}

export interface FormRule {
  id: PrimaryKey;
  kind: RuleKind;
  image: URL | null;
  image_width: number;
  image_border_type: "bold" | "thin" | "none";
  image_border_color: string;
  tab_title?: string;
  is_enabled: boolean;
  description?: string;
  order: number;
  amounts: Array<FormRuleAmount>;
  notice_box?: string;
  notice_box_type: "warning" | "success" | "info" | "error";
  switch_CTA_label: string;
  is_pending_custom_rule?: boolean;
  is_completed_custom_rule?: boolean;
  acquisition_source: string;

  rule: Rule;
  rule_spending_method: "fixed" | "percent" | "round_up";
}

export interface Rule {
  pk: PrimaryKey;
  id: PrimaryKey;
  title: string;
  template: RuleTemplate | null;
  type: RuleType;
  kind: RuleKind;
  is_active: boolean;
  trigger_lower: string;
  trigger_upper: string;
  trigger_noun_single_lower: string;
  cron: string;
}

export interface RuleTemplate extends Rule {
  cause_areas: Array<{ pk: number; name: string }>;
  image: URL | null;
  description_with_cta: string;
  triggers_per_month_expected: number | null;
  template: null;
  is_pending_custom_rule: boolean;
  rule_spending_method?: "fixed" | "percent" | "round_up";
  amounts?: Array<number>;
}

export interface CauseArea {
  pk: PrimaryKey;
  name: string;
}

export type UUID = string;

export interface RuleEarmarking {
  id?: PrimaryKey;
  uuid: UUID;
  charity?: PrimaryKey;
  label: string;
  identifier?: string;
  order: number;
  is_archived: boolean;
}

export async function getFormConfigStaticProps(context: GetServerSidePropsContext): Promise<{
  props: DonationFormConfig;
  revalidate?: number;
}> {
  const formId = process.env.NEXT_PUBLIC_API_CMS_ID ?? process.env.API_CMS_FORM_ID;
  const formUrl = `${process.env.API_CMS_URL}${formId}/`;
  const formConfigRaw: DonationFormConfigRaw = (await axios.get(formUrl)).data;

  const oneHour = 60 * 60;
  return {
    props: {
      ...(await deserializerFormConfigRaw(formConfigRaw)),
      is_org_pardes: formConfigRaw.id === 243,
    },
    revalidate: oneHour * 6,
  };
}

export async function deserializerFormConfigRaw(
  formRaw: DonationFormConfigRaw,
): Promise<DonationFormConfig> {
  for (const formRule of formRaw.rules) {
    // there seem to be a bug that only appears during user creation
    // when on /create-or-fetch-latest it returns an array of strings here
    formRule.amounts = formRule.amounts.map(amountObj => ({
      ...amountObj,
      amount: Number(amountObj.amount),
    }));
  }

  const formConfig = formRaw as DonationFormConfig;
  if (formConfig.is_progress_bar_enabled) {
    formConfig.progress_bar_raised = await getTotalDonated(formConfig.charity.id);
  }

  return formConfig as DonationFormConfig;
}

export function getTabTitle(formRule: FormRule): string {
  if (formRule.tab_title) {
    return formRule.tab_title;
  }
  if (["event_based", "impact_based"].includes(formRule.rule.kind)) {
    return "personal";
  }
  return formRule.rule.kind;
}

export type AddressFieldsOption =
  | "required_political"
  | "required"
  | "optional"
  | "disabled";

async function getTotalDonated(charityId: number): Promise<number> {
  let totalDollars = 0;
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL_V2}statistics/donations/withdrawn?charity_ids=${charityId}`,
    );
    totalDollars = response.data.total_dollars;
  } catch {
    // backend throws an error when no money committed or BT is down on local setup
  }
  return totalDollars;
}
