import { DonationFormPageComponent } from "donation-form/src/components/form";
import {
  DonationFormConfig,
  getFormConfigStaticProps,
} from "donation-form/src/utils/api-cms";

export default function DonationFormPage(props: DonationFormConfig) {
  return <DonationFormPageComponent formConfig={props} />;
}

export const getStaticProps = context => getFormConfigStaticProps(context);
