import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Center,
  Collapse,
  Text,
  Tooltip,
  useBoolean,
  useBreakpointValue,
  useTheme,
} from "@chakra-ui/react";
import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useFormConfig } from "donation-form/src/components/form";
import { BackButton } from "donation-form/src/components/form/shared/BackButton";
import { PaymentFormError } from "donation-form/src/components/form/shared/PaymentFormError";
import { PaymentPlaid } from "donation-form/src/components/form/Step2Payment/PaymentPlaid";
import { StripeCardInput } from "donation-form/src/components/form/Step2Payment/StripeCardInput";
import {
  FormStep,
  PaymentMethod,
  useFormControl,
} from "donation-form/src/components/form/useFormControl";
import { tracker } from "donation-form/src/utils/tracker";
import * as React from "react";
import { useEffect } from "react";
import { BsBank2, BsCreditCardFill } from "react-icons/bs";
import { CardBox } from "donation-form/src/components/form/Step2Payment/CardBox";

export function Step2Payment() {
  const [isStripeMissingFields, setIsStripeMissingFields] = useBoolean();
  const [isErrorOccurred, setIsErrorOccurred] = useBoolean();

  const formConfig = useFormConfig();
  const form = useFormControl();

  const stripe = useStripe()!;
  const stripeElements = useElements()!;
  const theme = useTheme();

  const isPaymentConnected =
    (form.isPlaidPay() && form.isPlaidConnected()) ||
    (form.isStripePay() && form.isStripeConnected());

  const isCanTrackPlaidButNotCharge =
    form.isPlaidConnected() && !form.isPlaidChargeable() && form.isSpendingRule();
  const isShowTopPaySelectButtons =
    (!form.isSpendingRule() || form.isPlaidConnected()) &&
    !isCanTrackPlaidButNotCharge &&
    formConfig.is_enable_ach;

  const checkoutProps: tracker.CheckoutStep = {
    step: FormStep.Step2PaymentConfig,
    payment_method: "card",
    form,
    config: formConfig,
  };

  useEffect(() => {
    tracker.trackFormStep(checkoutProps, formConfig, form);
  }, []);

  const handleStripePaymentAndRedirect = async () => {
    setIsStripeMissingFields.off();
    form.isCardValidationError.set(false);
    setIsErrorOccurred.off();

    let paymentMethod;
    if (form.paymentMethod === PaymentMethod.Plaid) {
      paymentMethod = "plaid";
    } else {
      const stripeTokenResponse = await stripe.createToken(
        stripeElements.getElement(CardNumberElement)!,
      );
      if (stripeTokenResponse.token) {
        tracker.paymentInfoEntered({
          ...(checkoutProps as any),
          payment_brand: stripeTokenResponse.token?.card?.brand,
        });
        form.setStripeToken(stripeTokenResponse.token);
        form.setStripeCardInfo(stripeTokenResponse.token.card!);
        paymentMethod = "card";
      } else if (stripeTokenResponse.error) {
        switch (stripeTokenResponse.error.type) {
          case "validation_error":
            return setIsStripeMissingFields.on();
          default:
            return setIsErrorOccurred.on();
        }
      } else {
        return setIsErrorOccurred.on();
      }
    }
    checkoutProps.payment_method = paymentMethod;
    tracker.checkoutStepSubmitted(checkoutProps);
    form.setFormStep(FormStep.Step3UserInfo);
  };

  const buttonSizeArray = ["md", "lg"];
  const buttonSize = useBreakpointValue(buttonSizeArray) ?? buttonSizeArray[1];

  const isPlaidConnectedButCantCharge =
    form.isPlaidConnected() && form.isSpendingRule() && !form.isPlaidChargeable();

  return (
    <>
      <BackButton onClick={() => form.setFormStep(FormStep.Step1Rule)} />

      <Collapse
        in={
          form.isPlaidConnected() && form.isSpendingRule() && !isPlaidConnectedButCantCharge
        }
      >
        <Alert status="success" mt={theme.space.md} mb={theme.space.md}>
          <AlertIcon />
          Bank connected
        </Alert>
      </Collapse>

      <Text
        as="h2"
        textStyle="h2"
        mt={form.isSpendingRule() ? theme.space.sm : theme.space.md}
      >
        {form.isSpendingRule() && !form.isPlaidConnected() && "Track Your Purchases"}
        {!(form.isSpendingRule() && !form.isPlaidConnected()) &&
          formConfig.is_enable_ach &&
          "Payment Method"}
        {!(form.isSpendingRule() && !form.isPlaidConnected()) &&
          !formConfig.is_enable_ach &&
          "Payment"}
      </Text>

      {isShowTopPaySelectButtons && (
        <ButtonGroup
          size={buttonSize}
          isAttached
          variant="group"
          w="100%"
          mt={theme.space.md}
          colorScheme="gray"
        >
          <Button
            onClick={() => form.setPaymentMethod(PaymentMethod.CreditCard)}
            isActive={form.isCardPay()}
            leftIcon={(
              <Box mr={theme.space.sm}>
                <BsCreditCardFill size={26} />
              </Box>
            )}
            w="100%"
            textTransform="capitalize"
          >
            Card
          </Button>

          <Tooltip
            label="Charity will get 2% more of your donation due to lower transaction fees!"
            placement="top"
            textAlign="center"
          >
            <Button
              onClick={() => form.setPaymentMethod(PaymentMethod.Plaid)}
              isActive={form.isPlaidPay()}
              leftIcon={(
                <Box mr={theme.space.sm}>
                  <BsBank2 size={26} />
                </Box>
              )}
              rightIcon={(
                <Box
                  mb="16px"
                  ml={1}
                  fontSize="11px"
                  color="white"
                  bg="green.500"
                  borderRadius={30}
                  px="5px"
                  pb="2px"
                >
                  +2%
                </Box>
              )}
              w="100%"
              textTransform="capitalize"
            >
              Bank
            </Button>
          </Tooltip>
        </ButtonGroup>
      )}

      <Collapse in={form.isPlaidPay() || form.isSpendingRule()}>
        <PaymentPlaid />
      </Collapse>

      <Collapse in={isPlaidConnectedButCantCharge}>
        <Text mt={theme.space.md}>
          Your account is connected to track spending, but ACH payment is unavailable for
          this account. Please provide a credit card as a payment method:
        </Text>
      </Collapse>

      <Collapse in={form.isCardPay()}>
        {form.stripeCardInfo && (
          <Box mt={theme.space.md}>
            <Box>Card saved:</Box>
            <CardBox brand={form.stripeCardInfo.brand} last4={form.stripeCardInfo.last4} />
          </Box>
        )}
        {!form.stripeCardInfo && (
          <Box>
            {form.isSpendingRule() && !isPlaidConnectedButCantCharge && (
              <Text mt={theme.space.md}>Or pay with a card:</Text>
            )}
            <StripeCardInput
              mt={form.isSpendingRule() ? theme.space.sm : theme.space.md}
              isStripeMissingFields={isStripeMissingFields}
            />
          </Box>
        )}
      </Collapse>

      <Collapse in={(isPaymentConnected && form.isPlaidChargeable()) || form.isCardPay()}>
        <PaymentFormError isVisible={form.isCardValidationError.val}>
          The credit card didn't pass validation, it might be caused by insufficient funds or
          incorrect data, please try again or contact support at support@givemomentum.com
        </PaymentFormError>

        <PaymentFormError isVisible={isErrorOccurred}>
          An error occurred, please try again or contact support at support@givemomentum.com
        </PaymentFormError>

        <PaymentFormError isVisible={isStripeMissingFields}>
          The credit card didn't pass validation, please try adding all required fields or
          another card
        </PaymentFormError>

        <Center mt={theme.space.lg}>
          <Button
            onClick={async () => {
              if (form.isCardPay() && !form.isStripeConnected()) {
                await handleStripePaymentAndRedirect();
              } else {
                form.setFormStep(FormStep.Step3UserInfo);
              }
            }}
            variant="solid"
            size="lg"
          >
            Next
          </Button>
        </Center>
      </Collapse>
    </>
  );
}
