import { Checkbox, Flex, FormControl, FormLabel, Heading, HStack, Radio, RadioGroup, Stack, useTheme } from "@chakra-ui/react";
import { FormikProps } from "formik";
import { TextareaControl } from "formik-chakra-ui";
import * as React from "react";
import { FlushedInputControl } from "donation-form/src/components/form/shared/FlushedInputControl";
import { useAddressInputs } from "donation-form/src/components/form/Step3UserInfo/useAddressInputs";
import { useFormControl } from "donation-form/src/components/form/useFormControl";
import * as Yup from "yup";

export function useDedicationForm() {
  const form = useFormControl();
  const theme = useTheme();

  const hooks = {
    addressInputs: useAddressInputs({
      addressDataInitial: form.donationDedicationNotifyRecipient.val,
      fieldPrefix: "notify",
      isRequired: form.isDedicateDonation.val && form.donationDedicationNotifyType.val === "mail",
      mt: 0,
    }),
  };

  return {
    getInitialValues: () => ({
      dedicated_to: form.donationDedicatee.val ?? "",
      notify_first_name: form.donationDedicationNotifyRecipient.val?.first_name ?? "",
      notify_last_name: form.donationDedicationNotifyRecipient.val?.last_name ?? "",
      notify_email: form.donationDedicationNotifyRecipient.val?.email ?? "",
      notify_message: form.donationDedicationNotifyMessage.val ?? "",
      ...hooks.addressInputs.getInitialValues(),
    }),
    getValidationSchema: () => ({
      ...(form.isDedicateDonation.val && form.donationDedicationNotifyType.val !== "none" ? { notify_first_name: Yup.string().required("Required") } : {}),
      ...(form.isDedicateDonation.val && form.donationDedicationNotifyType.val === "email" ? { notify_email: Yup.string().required("Required") } : {}),
      ...(form.isDedicateDonation.val ? { dedicated_to: Yup.string().required("Required") } : {}),
      ...hooks.addressInputs.getValidationSchema(),
    }),
    saveFormData: (data: any) => {
      form.donationDedicatee.set(data.dedicated_to);
      form.donationDedicationNotifyMessage.set(data.notify_message);
      form.donationDedicationNotifyRecipient.set({
        first_name: data.notify_first_name,
        last_name: data.notify_last_name,
        email: data.notify_email,

        address: data[hooks.addressInputs.getFieldName("address")],
        city: data[hooks.addressInputs.getFieldName("city")],
        zip: data[hooks.addressInputs.getFieldName("zip")],
        state: data[hooks.addressInputs.getFieldName("state")],
        country: data[hooks.addressInputs.getFieldName("country")],
      });
    },
    render: (formik: FormikProps<any>) => (
      <Flex direction="column" gap={3} mt={3}>

        <Checkbox
          isChecked={form.isDedicateDonation.val}
          onChange={e => form.isDedicateDonation.set(e.target.checked)}
        >
          Dedicate this donation
        </Checkbox>

        {form.isDedicateDonation.val && (
          <Flex direction="column" gap={theme.space.sm}>
            <Heading fontSize="2xl" mt={1}>Dedication</Heading>

            <FormControl>
              <RadioGroup
                onChange={form.donationDedicationType.set}
                value={form.donationDedicationType.val}
              >
                <Stack direction="row">
                  <Radio value="in_honor_of">In honor of</Radio>
                  <Radio value="in_memory_of">In memory of</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>

            <HStack spacing={theme.space.md} alignItems="top">
              <FlushedInputControl name="dedicated_to" placeholder="Tribute Name" />
            </HStack>

            <FormControl mt={2}>
              <FormLabel>
                Would you like us to notify anyone of your donation?
              </FormLabel>
              <RadioGroup
                onChange={form.donationDedicationNotifyType.set}
                value={form.donationDedicationNotifyType.val}
              >
                <Flex direction="column" gap={2}>
                  <Radio value="mail">Yes, mail a card</Radio>
                  <Radio value="email">Yes, send an eCard</Radio>
                  <Radio value="none">No thanks</Radio>
                </Flex>
              </RadioGroup>
            </FormControl>

            {form.donationDedicationNotifyType.val !== "none" && (
              <HStack spacing={theme.space.md} alignItems="top">
                <FlushedInputControl
                  name="notify_first_name"
                  placeholder="Recipient First Name*"
                />
                <FlushedInputControl
                  name="notify_last_name"
                  placeholder="Recipient Last Name"
                />
              </HStack>
            )}

            {form.donationDedicationNotifyType.val === "email" && (
              <FlushedInputControl name="notify_email" placeholder="E-Mail" />
            )}

            {form.donationDedicationNotifyType.val === "mail" && (
              hooks.addressInputs.render(formik)
            )}

            {form.donationDedicationNotifyType.val !== "none" && (
              <Flex mt={3}>
                <TextareaControl name="notify_message" label="Notification Details" />
              </Flex>
            )}
          </Flex>
        )}

      </Flex>
    ),
  };
}
