import { Alert, AlertIcon, Box, Center, Image, Stack, useTheme } from "@chakra-ui/react";
import { Theme } from "@chakra-ui/theme";
import { captureException } from "@sentry/nextjs";
import { useContext } from "react";
import { DonationFormConfigContext } from "donation-form/src/components/form";
import { AmountSelect } from "donation-form/src/components/form/Step1Rule/AmountSelect";
import { MonthlyLimit } from "donation-form/src/components/form/Step1Rule/MonthlyLimit";
import { FormRule } from "donation-form/src/utils/api-cms";
import { FormControlContext } from "donation-form/src/components/form/useFormControl";

export function RulePane(props: { formRule: FormRule }) {
  const theme: Theme | any = useTheme();
  const configCtx = useContext(DonationFormConfigContext);
  const ruleCtx = useContext(FormControlContext);

  const isImgBorder = props.formRule.image_border_type !== "none";
  let borderWidth: string = "";
  switch (props.formRule.image_border_type) {
    case "bold":
      borderWidth = "3px";
      break;
    case "thin":
      borderWidth = "2px";
      break;
    case "none":
      borderWidth = "0px";
      break;
    default:
      captureException(new Error("invalid image border"));
  }

  if (ruleCtx.formRule === props.formRule) {
    return (
      <Stack align="center" spacing={theme.space.md}>
        {(props.formRule.image || props.formRule.rule?.template?.image) && (
          <Box
            border={isImgBorder ? `${borderWidth} solid` : ""}
            borderColor={isImgBorder ? props.formRule.image_border_color : ""}
            borderRadius={isImgBorder ? "3px" : ""}
            w="fit-content"
            h="fit-content"
            d="flex"
            bg={isImgBorder ? props.formRule.image_border_color : ""}
          >
            <Image
              src={props.formRule.image || props.formRule.rule?.template?.image!}
              fallback={(
                <Box
                  height={props.formRule.image_width}
                  width={props.formRule.image_width}
                />
              )}
              maxW={props.formRule.image_width}
              objectFit="contain"
              alt="logo"
            />
          </Box>
        )}

        {(props.formRule.description ??
          props.formRule.rule.template?.description_with_cta) && (
          <Center
            dangerouslySetInnerHTML={{
              __html: (props.formRule.description ??
                props.formRule.rule.template?.description_with_cta)!,
            }}
          />
        )}

        <AmountSelect
          amountOptions={props.formRule.amounts}
          amountSelected={ruleCtx.amount}
          onChange={value => ruleCtx.setAmount(value)}
        />

        {ruleCtx.isPersonalRule() && (
          <Box w="100%">
            <MonthlyLimit />
          </Box>
        )}

        {props.formRule.notice_box && (
          <Alert status={props.formRule.notice_box_type}>
            <AlertIcon />
            <Box dangerouslySetInnerHTML={{ __html: props.formRule.notice_box }} />
          </Alert>
        )}
      </Stack>
    );
  }
  return null;
}
