import { Icon } from "@chakra-ui/icons";
import { Box, useTheme } from "@chakra-ui/react";
import * as React from "react";
import { BsCreditCard2Back } from "react-icons/bs";
import { SiMastercard, SiVisa } from "react-icons/si";

export function CardBox(props: { brand: string; last4: string }) {
  const theme = useTheme();

  if (props.brand.toLowerCase() === "mastercard") {
    return (
      <Box>
        <Icon as={SiMastercard} h="27px" w="27px" mt="1px" />
        <Box ml={theme.space.md}> **** {props.last4}</Box>
      </Box>
    );
  }
  if (props.brand.toLowerCase() === "visa") {
    return (
      <Box d="flex" alignItems="center">
        <Icon as={SiVisa} h="36px" w="36px" mt="1px" />
        <Box ml={theme.space.md}> **** {props.last4}</Box>
      </Box>
    );
  }
  return (
    <Box>
      <Icon as={BsCreditCard2Back} h="36px" w="36px" />
      <Box ml={theme.space.md}> **** {props.last4}</Box>
    </Box>
  );
}
