import {
  HStack,
  InputRightElement,
  InputGroup,
  Input,
  Select,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { tipDefault } from "donation-form/src/components/form";
import { FormControlContext } from "donation-form/src/components/form/useFormControl";

export function Tip() {
  const [tipOther, setTipOther] = useState<undefined | number>(undefined);
  const [tip, setTip] = useState<"other" | number>(tipDefault);
  const theme = useTheme();
  const ruleCtx = useContext(FormControlContext);
  return (
    <>
      <Text as="h2" textStyle="h2" mt={theme.space.md}>
        Tip
      </Text>
      <Text mt={theme.space.md}>
        This donation is powered by Momentum. We provide our service for free to charities -
        we rely on your generosity to keep helping them improve the world.
      </Text>

      <HStack mt={theme.space.md} align="top" spacing={theme.space.md}>
        <Text mt={theme.space.sm}>Tip amount</Text>

        <Select
          value={tip}
          maxW="fit-content"
          _focus={{ borderColor: "brand.500" }}
          onChange={event => {
            if (event.target.value === "other") {
              setTip(event.target.value);
              setTipOther(tipDefault);
              ruleCtx.setTip(tipDefault);
            } else {
              setTipOther(undefined);
              const tipNew = Number(event.target.value);
              setTip(tipNew);
              ruleCtx.setTip(tipNew);
            }
          }}
        >
          <option value={10}>10%</option>
          <option value={15}>15%</option>
          <option value={20}>20%</option>
          <option value="other">Other</option>
        </Select>

        {tipOther !== undefined && (
          <InputGroup h="auto" maxW={78}>
            <InputRightElement children="%" pointerEvents="none" color="gray.400" />
            <Input
              autoFocus
              value={tipOther}
              maxLength={2}
              onChange={event => {
                const tipNew = Number(event.target.value);
                setTipOther(tipNew);
                ruleCtx.setTip(tipNew);
              }}
            />
          </InputGroup>
        )}
      </HStack>
    </>
  );
}
