import { ArrowBackIcon } from "@chakra-ui/icons";
import { Button, useTheme } from "@chakra-ui/react";

export function BackButton(props: { onClick: any; isDisabled?: boolean }) {
  const theme = useTheme();
  return (
    <Button
      variant="link"
      onClick={props.onClick}
      disabled={props.isDisabled ?? false}
      mr="auto"
      color="gray.400"
    >
      <ArrowBackIcon mr={theme.space.sm} /> Back
    </Button>
  );
}
