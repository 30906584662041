import { InputControl } from "formik-chakra-ui";
import { useFormikContext } from "formik";
import { FormEvent } from "react";

export function FlushedInputControl(props: {
  name: string;
  placeholder?: string;
  mt?: number;
  inputProps?: any;
  onChange?: (event) => void;
}) {
  const { setValues, values } = useFormikContext();
  return (
    <InputControl
      name={props.name}
      mt={props.mt}
      onChange={event => {
        if (props.onChange) {
          props.onChange(event);
        }
      }}
      inputProps={{
        // this listener fixes the missing autofill detection,
        // otherwise Formik considers the autofilled inputs empty
        ...props.inputProps,
        placeholder: props.placeholder ?? capitalize(props.name),
        variant: "flushed",
        onInput: (event: FormEvent<HTMLInputElement>) => {
          (values as any)[props.name] = (event?.target as any).value ?? "";
          setValues(values);
        },
      }}
    />
  );
}

function capitalize(str: string) {
  return (
    str
      // insert a space before all caps
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, str => str.toUpperCase())
  );
}
