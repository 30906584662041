import {
  SimpleGrid,
  GridItem,
  InputLeftElement,
  InputGroup,
  Input,
  Box,
  Button,
  useTheme,
  useToken,
  useBoolean,
  useBreakpointValue,
  InputRightElement,
  Tooltip,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useFormControl } from "donation-form/src/components/form/useFormControl";
import { FormRuleAmount } from "donation-form/src/utils/api-cms";
import { useFormConfig } from "donation-form/src/components/form";

export function AmountSelect(props: {
  amountOptions: FormRuleAmount[];
  amountSelected: number | null;
  onChange: (value: number | null) => any;
}) {
  const form = useFormControl();
  const formConfig = useFormConfig();
  const [isAmountOtherSelected, setIsAmountOtherSelected] = useBoolean();
  const amountOtherInput = useRef(null as any);

  useEffect(() => {
    if (isAmountOtherSelected) {
      amountOtherInput.current?.focus();
      amountOtherInput.current?.select();
    }
    const isCurrentAmountExistOnThisKind = props.amountOptions.find(
      amountObj => props.amountSelected === amountObj.amount,
    );
    if (!isCurrentAmountExistOnThisKind) {
      form.setAmount(props.amountOptions[1].amount);
    }
  }, [form.formRule, isAmountOtherSelected]);

  useEffect(() => {
    if (form.isCoveringFees.val) {
      if (Number(form.amount) >= 1000) {
        form.feesCoveringPercentage.set(2);
      } else {
        form.feesCoveringPercentage.set(4);
      }
    }
  }, [form.amount]);

  const theme = useTheme();
  const [primary600] = useToken("colors", [`${theme.config.colorScheme}.600`]);

  const isOptionSelected = (amountObj: FormRuleAmount) => props.amountSelected === amountObj.amount && !isAmountOtherSelected;

  const buttonSizeArray = ["md", "lg"];
  const buttonSize = useBreakpointValue(buttonSizeArray) ?? buttonSizeArray[1];

  if (form.isSpendingRule() && form.getSpendingRule()?.rule_spending_method === "round_up") {
    return null;
  }

  // A hack for some kind of grid bug in iframe.
  // Without it the size of amounts is incorrect until you trigger at least one rerender *for each Button*.
  // A rerender can also be triggered by a click or a slight window size change,
  // returning null is silly but also the simplest way to enforce it
  const [isShowAmounts, setIsShowAmounts] = useBoolean(true);
  useEffect(() => {
    setIsShowAmounts.off();
    setTimeout(() => {
      setIsShowAmounts.on();
    }, 100);
  }, []);
  if (!isShowAmounts) {
    return null;
  }

  const fontSize = ["0.85rem", "1rem", "lg"];

  const amountHints: string[] = [];
  for (const amount of props.amountOptions) {
    let amountHint = "";
    if (form.formRule.rule.kind === "schedule") {
      amountHint = `$${amount.amount?.toLocaleString()} per month`;
    } else {
      amountHint = `$${amount.amount?.toLocaleString()}`;
    }
    amountHints.push(amountHint);
  }
  let amountHint: string;
  if (form.formRule.rule.kind === "schedule") {
    amountHint = `Amount: $${props.amountSelected?.toLocaleString()} per month`;
  } else {
    amountHint = `Amount: $${props.amountSelected?.toLocaleString()}`;
  }

  return (
    <Box w="100%">
      <SimpleGrid
        columns={formConfig.is_org_pardes ? [3, 3, 3] : [3, 3, 5]}
        spacing={4}
        w="100%"
        autoRows="1fr"
      >
        {formConfig.is_org_pardes &&
          props.amountOptions.map((amountObj, index) => (
            <Tooltip label={amountHints[index] ?? ""} key={index}>
              <Button
                /* eslint-disable react/no-array-index-key */
                onClick={() => {
                  setIsAmountOtherSelected.off();
                  props.onChange(amountObj.amount);
                }}
                colorScheme={isOptionSelected(amountObj) ? theme.config.colorScheme : "gray"}
                boxShadow={
                  isOptionSelected(amountObj) ? `0 0 0 1px ${primary600} !important` : ""
                }
                variant="outline"
                size={buttonSize}
                w="100%"
                fontSize={fontSize}
              >
                {renderAmountLabel(amountObj)}
              </Button>
            </Tooltip>
          ))}
        {!formConfig.is_org_pardes &&
          props.amountOptions.map((amountObj, index) => (
            <Button
              key={index}
              /* eslint-disable react/no-array-index-key */
              onClick={() => {
                setIsAmountOtherSelected.off();
                props.onChange(amountObj.amount);
              }}
              colorScheme={isOptionSelected(amountObj) ? theme.config.colorScheme : "gray"}
              boxShadow={
                isOptionSelected(amountObj) ? `0 0 0 1px ${primary600} !important` : ""
              }
              variant="outline"
              size={buttonSize}
              w="100%"
              fontSize={fontSize}
            >
              {renderAmountLabel(amountObj)}
            </Button>
          ))}

        {!formConfig.is_org_pardes && (
          <GridItem colSpan={[2, null, 1]} h="100%">
            {!isAmountOtherSelected && (
              <Button
                onClick={setIsAmountOtherSelected.on}
                size={buttonSize}
                w="100%"
                variant="outline"
                colorScheme="gray"
              >
                Other
              </Button>
            )}
            {isAmountOtherSelected && (
              <InputGroup h="100%">
                {!form.isPercentBasedRule() && (
                  <InputLeftElement
                    children="$"
                    pointerEvents="none"
                    color="gray.400"
                    top={0}
                    bottom={0}
                    m="auto"
                  />
                )}
                <Input
                  ref={amountOtherInput as any}
                  onClick={setIsAmountOtherSelected.on}
                  onFocus={setIsAmountOtherSelected.on}
                  autoFocus
                  value={props.amountSelected ?? ""}
                  onChange={event => {
                    if (event.target.value) {
                      const valueNew = Number(event.target.value);
                      if (valueNew > 0) {
                        props.onChange(valueNew);
                      } else {
                        props.onChange(event.target.value as any);
                      }
                    } else {
                      props.onChange(null);
                    }
                  }}
                  boxShadow={`0 0 0 1px ${primary600} !important`}
                  borderColor={primary600}
                  color={primary600}
                  fontWeight="semibold"
                  label="Other"
                  variant="outline"
                  type="number"
                  min={0}
                  size={buttonSize}
                  h="auto"
                  textAlign={form.isPercentBasedRule() ? "right" : "left"}
                />
                {form.isPercentBasedRule() && (
                  <InputRightElement
                    children="%"
                    pointerEvents="none"
                    color="gray.400"
                    top={0}
                    bottom={0}
                    m="auto"
                  />
                )}
              </InputGroup>
            )}
          </GridItem>
        )}
      </SimpleGrid>

      {formConfig.is_org_pardes && amountHint && (
        <Box mt={4} fontSize={["0.85rem", "1rem", "lg"]} color="gray.500">
          {amountHint}
        </Box>
      )}
    </Box>
  );
}

function renderAmountLabel(amountObj: FormRuleAmount): string {
  const value = amountObj.amount;
  const form = useFormControl();

  if (amountObj.label_override) {
    return amountObj.label_override;
  }

  if (form.isPercentBasedRule()) {
    return `${value}%`;
  }
  if (Number.isInteger(value)) {
    return `$${new Intl.NumberFormat("en-US").format(value)}`;
  }
  const amountDollars = value;
  let amountDollarsStr = amountDollars.toFixed(2);
  if (amountDollarsStr === "0.00") {
    amountDollarsStr = amountDollars.toFixed(3);
  }
  if (amountDollarsStr === "0.000") {
    amountDollarsStr = amountDollars.toFixed(4);
  }
  return `$${amountDollarsStr}`;
}
