import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { BackButton } from "donation-form/src/components/form/shared/BackButton";
import { FlushedInputControl } from "donation-form/src/components/form/shared/FlushedInputControl";
import { UserData } from "donation-form/src/utils/api-create-pledge";
import { tracker } from "donation-form/src/utils/tracker";
import { Form, Formik } from "formik";
import * as React from "react";
import { useContext, useEffect } from "react";
import { DonationFormConfigContext } from "donation-form/src/components/form";
import { FormStep, useFormControl } from "donation-form/src/components/form/useFormControl";
import { useDedicationForm } from "donation-form/src/components/form/Step3UserInfo/useDedicationForm";
import { useUserInfoForm } from "donation-form/src/components/form/Step3UserInfo/useUserInfoForm";
import * as Yup from "yup";

export function Step3UserInfo() {
  const formConfig = useContext(DonationFormConfigContext);
  const form = useFormControl();
  const theme = useTheme();

  const hooks = {
    userForm: useUserInfoForm(),
    dedicationForm: useDedicationForm(),
  };

  const checkoutProps: tracker.CheckoutStep = {
    step: 2,
    payment_method: "card",
    form,
    config: formConfig,
  };

  useEffect(() => {
    tracker.trackFormStep(checkoutProps, formConfig, form);
  }, []);

  const handleSubmit = async (userData: UserData & any) => {
    tracker.checkoutStepSubmitted({ ...checkoutProps, userData });
    hooks.userForm.saveFormData(userData);
    if (formConfig.is_enable_donation_dedication) {
      hooks.dedicationForm.saveFormData(userData);
    }
    form.setFormStep(FormStep.Step4Review);
  };

  return (
    <Box>
      <BackButton onClick={() => form.setFormStep(FormStep.Step2PaymentConfig)} />

      <Text as="h2" textStyle="h2" mt={theme.space.md}>
        Your Information
      </Text>

      <Formik
        initialValues={{
          ...hooks.userForm.getInitialValues(),
          ...(formConfig.is_enable_donation_dedication ? hooks.dedicationForm.getInitialValues() : {}),
          on_behalf_of: form.donationOnBehalfOf.val ?? "",
        }}
        validationSchema={Yup.object().shape({
          ...hooks.userForm.getValidationSchema(),
          ...(formConfig.is_enable_donation_dedication ? hooks.dedicationForm.getValidationSchema() : {}),
        })}
        onSubmit={handleSubmit}
        validateOnBlur={false}

      >
        {formik => (
          <Form>

            {hooks.userForm.render(formik)}

            {formConfig.is_enable_donation_on_behalf_of && (
              <Flex mt={3} direction="column" gap={3}>
                <Checkbox
                  isChecked={form.isDonatingOnBehalfOf.val}
                  onChange={e => form.isDonatingOnBehalfOf.set(e.target.checked)}
                >
                  Make this gift on behalf of an organization
                </Checkbox>
                {form.isDonatingOnBehalfOf.val && (
                  <FlushedInputControl
                    name="on_behalf_of"
                    placeholder="Company/Organization Name"
                    onChange={event => {
                      form.donationOnBehalfOf.set(event.target.value);
                    }}
                    mt={-2}
                  />
                )}
              </Flex>
            )}

            {formConfig.is_enable_donation_dedication && (
              hooks.dedicationForm.render(formik)
            )}

            <Center mt={theme.space.lg}>
              <Button isLoading={formik.isSubmitting} type="submit" variant="solid" size="lg">
                Continue
              </Button>
            </Center>

          </Form>
        )}
      </Formik>
    </Box>
  );
}
