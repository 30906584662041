import {
  Box,
  Checkbox,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  Slider,
  useTheme,
} from "@chakra-ui/react";
import { useContext } from "react";
import { limitDefault } from "donation-form/src/components/form";
import { FormControlContext } from "donation-form/src/components/form/useFormControl";

export function MonthlyLimit() {
  const { limit, setLimit } = useContext(FormControlContext);
  const theme = useTheme();
  return (
    <>
      <Checkbox
        isChecked={Boolean(limit)}
        onChange={event => {
          setLimit(event.target.checked ? limitDefault : null);
        }}
        mt="1px"
      >
        Add a monthly limit
      </Checkbox>

      {Boolean(limit) && (
        <>
          <Text mt={theme.space.sm}>Limit: ${limit}</Text>
          <Slider
            defaultValue={limit ?? limitDefault}
            max={500}
            min={1}
            onChange={value => setLimit(value)}
            size="lg"
          >
            <SliderTrack h={2}>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb bg={`${theme.config.colorScheme}.800`} boxSize={6}>
              <Box boxSize={4} color="white" />
            </SliderThumb>
          </Slider>
        </>
      )}
    </>
  );
}
