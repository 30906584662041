import {
  SimpleGrid,
  GridItem,
  useTheme,
  Input,
  useBoolean,
  useMultiStyleConfig,
  useToken,
  Box,
  Link,
  Text,
  HStack,
} from "@chakra-ui/react";

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { FormTheme } from "donation-form/src/styles/theme";

import { LockIcon } from "@chakra-ui/icons";
import { Dispatch } from "react";

export function StripeCardInput(props: { isStripeMissingFields: boolean; mt?: number }) {
  const theme: FormTheme = useTheme();
  return (
    <Box>
      <SimpleGrid mt={props.mt ?? 0} columns={[2, null, 5]} spacing={theme.space.md}>
        <GridItem colSpan={[2, null, 3]}>
          <StripeInput
            as={CardNumberElement}
            isStripeMissingFields={props.isStripeMissingFields}
          />
        </GridItem>
        <StripeInput
          as={CardExpiryElement}
          isStripeMissingFields={props.isStripeMissingFields}
        />
        <StripeInput
          as={CardCvcElement}
          isStripeMissingFields={props.isStripeMissingFields}
        />
      </SimpleGrid>

      <HStack mt={theme.space.md} color="gray.500">
        <LockIcon mb="2px" />
        <Text flexDirection="row">
          Securely stored by{" "}
          <Link d="inline-block" isExternal href="https://stripe.com" fontWeight="semibold">
            Stripe
          </Link>
        </Text>
      </HStack>
    </Box>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
export function StripeInput(props: {
  isStripeMissingFields?: boolean;
  onZipChange?: Dispatch<any>;
  as?: any;
  placeholder?: string;
  [key: string]: any;
}) {
  const [isFocused, setIsFocused] = useBoolean();
  const [isTouched, setIsTouched] = useBoolean();
  const inputStyleDefault: any = useMultiStyleConfig("Input", {
    variant: "flushed",
  }).field;

  let inputStyle = {};
  if (isFocused) {
    inputStyle = inputStyleDefault._focus;
  } else if (props.isStripeMissingFields && !isTouched) {
    inputStyle = inputStyleDefault._invalid;
  }
  const [gray300] = useToken("colors", ["gray.300"]);
  const theme: FormTheme = useTheme();

  return (
    <Input
      onFocus={setIsFocused.on}
      onBlur={setIsFocused.off}
      variant="flushed"
      as={props.as}
      placeholder={props.placeholder}
      onChange={event => {
        if (props.isStripeMissingFields) {
          setIsTouched.on();
        }
        if (props.onZipChange) {
          props.onZipChange(event.target.value);
        }
      }}
      {...inputStyle}
      py="14px"
      h="49px"
      options={{
        style: {
          base: {
            fontFamily: theme.fonts.body,
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: gray300,
            },
          },
        },
      }}
    />
  );
}
