import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  HStack,
  InputGroup,
  InputRightElement,
  Spinner,
  Tooltip,
  useTheme,
} from "@chakra-ui/react";
import { countries } from "countries-list";
import { FormikProps } from "formik";
import { InputControl, SelectControl } from "formik-chakra-ui";
import * as React from "react";
import { useState } from "react";
import { geocodeByAddress } from "react-google-places-autocomplete";
import PlacesAutocomplete from "react-places-autocomplete";
import { FlushedInputControl } from "donation-form/src/components/form/shared/FlushedInputControl";
import { AddressFields, UserData } from "donation-form/src/utils/api-create-pledge";
import states from "states-us/dist";
import * as Yup from "yup";

export function useAddressInputs(props: {
  isShowPoliticalTooltip?: boolean;
  isRequired?: boolean;
  isOnlyUS?: boolean;
  addressDataInitial?: AddressFields | UserData | null;
  fieldPrefix?: string;
  mt?: number;
}) {
  const theme = useTheme();
  const [address, setAddress] = useState<string>(props.addressDataInitial?.address ?? "");

  function getFieldName(name: "address" | "city" | "state" | "zip" | "country"): string {
    if (props.fieldPrefix) {
      return `${props.fieldPrefix}_${name}`;
    }
    return name;
  }

  return {
    getFieldName,
    getInitialValues: () => {
      const base = {
        [getFieldName("address")]: props.addressDataInitial?.address ?? "",
        [getFieldName("city")]: props.addressDataInitial?.city ?? "",
        [getFieldName("zip")]: props.addressDataInitial?.zip ?? "",
        [getFieldName("state")]: props.addressDataInitial?.state ?? "",
      };
      if (props.isOnlyUS) {
        return {
          ...base,
          [getFieldName("country")]: "United States",
        };
      }
      return {
        ...base,
        [getFieldName("country")]: props.addressDataInitial?.country ?? "United States",
      };
    },
    getValidationSchema: () => {
      const base = {
        [getFieldName("address")]: Yup.string().required(),
        [getFieldName("city")]: Yup.string().required(),
        [getFieldName("zip")]: Yup.string().required(),
      };
      if (props.isRequired) {
        if (props.isOnlyUS) {
          return {
            ...base,
            [getFieldName("state")]: Yup.string().required(),
          };
        }
        return base;
      }
      return {};
    },
    render: (formik: FormikProps<any>) => (
      <>
        <Box mt={props.mt ?? theme.space.md}>
          <PlacesAutocomplete
            value={address}
            onChange={(value: string) => setAddress(value)}
            onSelect={async addressRaw => {
              const address = new Address(await geocodeByAddress(addressRaw));
              if (!formik) {
                return;
              }
              formik.values[getFieldName("address")]! = address.street ? `${address.streetNum} ${address.street}` : "";
              setAddress(formik.values[getFieldName("address")]);
              formik.values[getFieldName("city")] = address.city ?? "";
              formik.values[getFieldName("state")] = address.state ?? "";
              formik.values[getFieldName("zip")] = address.zip ?? "";
              formik.setValues(formik.values);
            }}
            searchOptions={{
              componentRestrictions: {
                country: ["us"],
              },
            }}
            shouldFetchSuggestions={address.length > 0}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <Box pos="relative">
                <InputGroup>
                  <FlushedInputControl
                    name={getFieldName("address")}
                    placeholder="Address"
                    inputProps={{
                      ...getInputProps({ maxLength: 34 }),
                    }}
                  />
                  {loading && <InputRightElement children={<Spinner />} />}
                </InputGroup>
                {suggestions.length >= 0 && (
                  <Box
                    boxShadow="0 2px 6px #0000003d"
                    pos="absolute"
                    bg="white"
                    zIndex={3}
                    w="100%"
                  >
                    {suggestions.map((suggestion, index) => (
                      <Box
                        key={suggestion.description}
                        {...getSuggestionItemProps(suggestion)}
                        px={3}
                        py={1}
                        borderBottomWidth={isLastItem(suggestions, index) ? "0" : "1px"}
                        borderStyle="solid"
                        borderColor="gray.200"
                        _hover={{
                          bg: "brand.50",
                          cursor: "pointer",
                        }}
                      >
                        {suggestion.description}
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            )}
          </PlacesAutocomplete>
        </Box>

        <HStack spacing={theme.space.md} mt={props.mt ?? theme.space.md} alignItems="top">
          <FlushedInputControl name={getFieldName("city")} placeholder="City" />
          <SelectControl
            name={getFieldName("state")}
            selectProps={{ placeholder: "State", variant: "flushed" }}
          >
            {states.map(state => (
              <option value={state.abbreviation} key={state.abbreviation}>
                {state.name}
              </option>
            ))}
          </SelectControl>
        </HStack>

        <HStack spacing={theme.space.md} mt={props.mt ?? theme.space.md} alignItems="top">
          <FlushedInputControl name={getFieldName("zip")} placeholder="Zip" />

          {props.isOnlyUS && (
            <InputGroup>
              <InputControl
                name={getFieldName("country")}
                inputProps={{
                  placeholder: "Country",
                  variant: "flushed",
                  value: "United States",
                  isDisabled: true,
                }}
              />
              {props.isShowPoliticalTooltip && (
                <InputRightElement>
                  <Tooltip
                    hasArrow
                    label="Due to federal law, only United States citizens or permanent residents are eligible to make donations."
                    closeOnClick={false}
                  >
                    <InfoOutlineIcon mt={theme.space.sm} color="brand.200" />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          )}
          {!props.isOnlyUS && (
            <SelectControl
              name={getFieldName("country")}
              selectProps={{ placeholder: "Country", variant: "flushed" }}
            >
              {Object.entries(countries).map(([code, country]) => (
                <option value={country.name} key={country.name}>
                  {country.name}
                </option>
              ))}
            </SelectControl>
          )}
        </HStack>
      </>
    ),
  };
}

const isLastItem = (array: Array<any>, index: number) => array.length === index + 1;

class Address {
  constructor(public geoData: Array<google.maps.GeocoderResult>) {}

  get street() {
    return this.getComponent("route", "long_name");
  }

  get streetNum() {
    return this.getComponent("street_number", "long_name");
  }

  get state() {
    return this.getComponent("administrative_area_level_1", "short_name");
  }

  get city() {
    return this.getComponent("locality", "long_name");
  }

  get zip() {
    return this.getComponent("postal_code", "long_name");
  }

  getComponent(type: AddressType, format: "short_name" | "long_name"): string | "" {
    const addressComponentMatched = this.geoData[0]?.address_components.find(component => component.types.includes(type));
    return (addressComponentMatched && addressComponentMatched[format]) ?? "";
  }
}

type AddressType =
  | "administrative_area_level_1"
  | "locality"
  | "postal_code"
  | "route"
  | "street_number";
