import { Token } from "@stripe/stripe-js";
import axios from "axios";
import { tracker } from "donation-form/src/utils/tracker";
import { DonationFormConfig } from "donation-form/src/utils/api-cms";
import * as Sentry from "@sentry/nextjs";
import {
  FormControlContextI,
  PlaidDetailsRequest,
} from "donation-form/src/components/form/useFormControl";

export const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL!,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosInstanceV2 = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL_V2!,
  headers: {
    "Content-Type": "application/json",
  },
});

export interface PledgeArgs {
  userData: UserData;
  formConfig: DonationFormConfig;
  form: FormControlContextI;
  stripeToken: Token;
  plaidDetailsRequest: PlaidDetailsRequest;
  isRecaptchaV3Failed: boolean;
  recaptchaV2Token?: string | null;
}

export async function createPledge(args: PledgeArgs) {
  Sentry.setContext("user", {
    configCtx: JSON.stringify(args.formConfig),
    userData: args.userData,
  });
  tracker.identify(args.userData);

  const { rule } = args.form.formRule;

  try {
    const response = await axiosInstance.post(
      "auth/register/credentials",
      serializeUserDetails({
        userData: args.userData,
        form: args.form,
        formConfig: args.formConfig,
      }),
    );
    trackUser(response.data.token);
  } catch (error: any) {
    const isUnexpectedError =
      error?.response?.data?.errors?.email !== "This user already exists";
    if (isUnexpectedError) {
      // todo skip network error
      console.error(error);
      Sentry.setContext("response", { res: JSON.stringify(error?.response?.data) });
      Sentry.captureException(error);
      return PledgeCreateResponse.ErrorUnknown;
    }
  }

  try {
    let captchaDetails;
    if (args.recaptchaV2Token) {
      captchaDetails = {
        recaptcha_v2: args.recaptchaV2Token,
      };
    } else {
      captchaDetails = {
        recaptcha_v3: await window.grecaptcha.execute(
          process.env.NEXT_PUBLIC_CAPTCHA_V3_PUBLIC_KEY!,
          { action: "create_pledge" },
        ),
      };
    }

    const userDetails: {
      email: string;
      first_name: string;
      last_name: string;
      title?: string;
      tip_percent?: number;
      address?: string;
      city?: string;
      zip?: string;
      state?: string;
      country?: string;
      employer?: string;
      occupation?: string;
      phone_number?: string;
    } = {
      email: args.userData.email,
      first_name: args.userData.firstName,
      last_name: args.userData.lastName,
      tip_percent: args.formConfig.is_enable_tip ? args.form.tip : 0,
    };
    if (args.userData?.salutation) {
      userDetails.title = args.userData?.salutation;
    }
    if (args.userData?.address) {
      userDetails.address = args.userData.address;
    }
    if (args.userData?.city) {
      userDetails.city = args.userData.city;
    }
    if (args.userData?.state) {
      userDetails.state = args.userData.state;
    }
    if (args.userData?.zip) {
      userDetails.zip = args.userData.zip;
    }
    if (args.userData?.country) {
      userDetails.country = args.userData.country;
    }
    if (args.userData?.employer) {
      userDetails.employer = args.userData.employer;
    }
    if (args.userData?.occupation) {
      userDetails.occupation = args.userData.occupation;
    }
    if (args.userData?.phone) {
      userDetails.phone_number = args.userData.phone;
    }

    const ruleDetails: {
      rule_id: number;
      charity_id?: number;

      fixed_dollars?: number;
      percent?: number;
      round_dollars?: number;

      covered_fee_percent?: number;

      limit_dollars?: number;
      earmarking_id?: number | null;
      campaign_id?: number;

      comment?: string;
      is_non_public_donation?: boolean;
      has_opted_in_to_charity_emails?: boolean;
      on_behalf_of?: string;
      dedicated_to?: string;

      extra_charity_data?: any;
    } = {
      rule_id: rule.id,
      campaign_id: Number(args.formConfig.unique_id),
      limit_dollars: args.form.limit ?? undefined,
      charity_id: args.formConfig.charity.id,
      ...getRuleAmountField(args),
      earmarking_id: args.form.earmarking! ? Number(args.form.earmarking!) : null,
      comment: args.userData.comment,

      covered_fee_percent: args.form.isCoveringFees.val ? args.form.feesCoveringPercentage.val : 0,

      is_non_public_donation: args.form.isDonationNonPublic.val,
      has_opted_in_to_charity_emails: args.form.isEmailSubscribe.val,

      on_behalf_of: args.form.donationOnBehalfOf.val ?? undefined,
      dedicated_to: args.form.isDedicateDonation.val ? args.form.donationDedicatee.val : "",

      extra_charity_data: args.form.isDedicateDonation.val ? {
        dedication_type: args.form.donationDedicationType.val,
        dedication_notify_type: args.form.donationDedicationNotifyType.val,
        dedication_notify_recipient: args.form.donationDedicationNotifyRecipient.val,
        dedication_notify_message: args.form.donationDedicationNotifyMessage.val,
      } : {},
    };

    const postBody: any = {
      user_details: userDetails,
      rule_details: ruleDetails,
      captcha_details: captchaDetails,
    };

    if (args.form.isPlaidConnected()) {
      postBody.plaid_details = args.plaidDetailsRequest;

      // Plaid funding
      if (args.form.isPlaidPay()) {
        postBody.plaid_details = {
          funding_account_id: args.form.plaidAccountId,
          ...args.plaidDetailsRequest,
        };
      }
    }

    // Stripe funding
    if (args.form.isStripePay() && args.form.isStripeConnected()) {
      postBody.card_details = {
        stripe_token: args.stripeToken.id,
      };
    }

    await axiosInstanceV2.post("pledge/", postBody);
  } catch (error: any) {
    if (error.response?.data?.code === "recaptcha_v3_failed") {
      return PledgeCreateResponse.ErrorCaptchaV3;
    }
    if (error.response?.data?.code === "recaptcha_v2_failed") {
      return PledgeCreateResponse.ErrorCaptchaV2;
    }
    if (error.response?.data?.code === "card_error") {
      return PledgeCreateResponse.ErrorCardInvalid;
    }
    // todo skip network error
    console.error(error);
    Sentry.setContext("response", { res: JSON.stringify(error?.response?.data) });
    Sentry.captureException(error);
    return PledgeCreateResponse.ErrorUnknown;
  }
  tracker.orderCompleted({
    acquisitionSource: args.form.formRule.acquisition_source,
    charityName: args.formConfig.charity.name,
    rule,
    ruleAmount: args.form.amount!,
    limit: args.form.limit,
  });
  return PledgeCreateResponse.Success;
}

function getRuleAmountField(args: PledgeArgs) {
  if (args.form.isSpendingRule()) {
    switch (args.form.getSpendingRule()?.rule_spending_method) {
      case "percent":
        return { percent: args.form.amount! };
      case "round_up":
        return { round_dollars: 1 };
      case "fixed":
        return { fixed_dollars: args.form.amount! };
    }
  }
  return { fixed_dollars: args.form.amount! };
}

function serializeUserDetails(args: {
  userData: UserData;
  form: FormControlContextI;
  formConfig: DonationFormConfig;
}) {
  return {
    email: args.userData.email,
    first_name: args.userData.firstName,
    last_name: args.userData.lastName,
    phone_number: args.userData.phone,
    name: `${args.userData.firstName} ${args.userData.lastName}`,
    password: Math.random().toString(16),
    acquisition_source: args.form.formRule.acquisition_source,
    device_type: "Web App",
    utc_offset: -new Date().getTimezoneOffset(), // getTimezoneOffset returns a reverse offset.
    tip: args.formConfig.is_enable_tip ? args.form.tip : 0,
    comment: args.userData.comment,
  };
}

export interface AddressFields {
  address: string;
  city: string;
  zip: string;
  state: string;
  country: "United States";
}

export interface UserData extends AddressFields {
  salutation?: string;
  email: string;
  firstName: string;
  lastName: string;
  employer: string;
  occupation: string;
  phone: string;
  comment: string;
}

export interface DedicationNotificationRecipient extends AddressFields {
  email?: string;
  first_name: string;
  last_name: string;
}

export enum PledgeCreateResponse {
  ErrorCaptchaV2,
  ErrorCaptchaV3,
  ErrorCardInvalid,
  ErrorUnknown,
  Success,
}

function trackUser(authToken: string) {
  axiosInstance
    .get("user/me", { headers: { Authorization: `Bearer ${authToken}` } })
    .then(res => {
      tracker.identify({ id: res.data.id, email: res.data.email });
    });
}
