import { Box } from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { Token } from "@stripe/stripe-js/types/api";
import { Card } from "@stripe/stripe-js/types/api/cards";
import { tipDefault, useFormConfig } from "donation-form/src/components/form/index";
import { Step1Rule } from "donation-form/src/components/form/Step1Rule";
import { Step2Payment } from "donation-form/src/components/form/Step2Payment";
import { Step3UserInfo } from "donation-form/src/components/form/Step3UserInfo";
import { Step4Review } from "donation-form/src/components/form/Step4Review";
import { Step5Success } from "donation-form/src/components/form/Step5Success";
import { DonationFormConfig, FormRule, UUID } from "donation-form/src/utils/api-cms";
import {
  DedicationNotificationRecipient,
  UserData,
} from "donation-form/src/utils/api-create-pledge";
import { getStripe } from "donation-form/src/utils/getStripe";
import { createContext, useContext, useEffect, useState } from "react";
import { PlaidAccount, PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { useStateVar } from "donation-form/src/hooks/useStateVar";

export function FormControlContextProvider(props: {
  children: any;
  config: DonationFormConfig;
}) {
  const form = setupFormControlContext({ formConfig: props.config });
  return (
    <FormControlContext.Provider value={form}>{props.children}</FormControlContext.Provider>
  );
}

export const FormControlContext = createContext<FormControlContextI>(
  {} as FormControlContextI,
);

export enum FormStep {
  Step1Rule,
  Step2PaymentConfig,
  Step3UserInfo,
  Step4Review,
  Step5Success,
}

export enum PaymentMethod {
  Plaid,
  CreditCard,
  PaymentRequest,
}

export type FormControlContextI = ReturnType<typeof setupFormControlContext>;

export interface PlaidDetailsRequest {
  public_token: string;
  spending_account_ids: string[];
}

/* not a comp because need ReturnType from this function */

/* eslint-disable react-hooks/rules-of-hooks */
export function setupFormControlContext(props: { formConfig: DonationFormConfig }) {
  const formConfig = useFormConfig();

  const ruleDefault = props.formConfig.rules.filter(rule => rule.is_enabled)[0];

  const [formStep, setFormStep] = useState<FormStep>(
    props.formConfig.step_current ?? FormStep.Step1Rule,
  );

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    PaymentMethod.CreditCard,
  );
  const [plaidAccountId, setPlaidAccountId] = useState<string | null>(null);
  const [plaidDetailsRequest, setPlaidDetailsRequest] =
    useState<Partial<PlaidDetailsRequest> | null>(null);
  const [plaidAccountsAvailable, setPlaidAccountsAvailable] = useState<
    PlaidAccount[] | null
  >(null);
  const [stripeToken, setStripeToken] = useState<Token | null>(null);
  const [stripeCardInfo, setStripeCardInfo] = useState<Card | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [formRule, setFormRule] = useState<FormRule>(ruleDefault);
  const [amount, setAmount] = useState<number | null>(ruleDefault.amounts[0].amount);
  const [limit, setLimit] = useState<number | null>(null);
  const [tip, setTip] = useState<number>(tipDefault);
  const [earmarking, setEarmarking] = useState<null | number>(null);

  const isDonationNonPublic = useStateVar<boolean>(false);
  const isCoveringFees = useStateVar<boolean>(
    formConfig.is_enable_fees_covering_field,
  );
  const feesCoveringPercentage = useStateVar<number>(4);
  const isEmailSubscribe = useStateVar<boolean>(
    formConfig.is_enable_newsletter_subscription_field,
  );
  const isDonatingOnBehalfOf = useStateVar<boolean>(false);
  const donationOnBehalfOf = useStateVar<string | null>(null);
  const isDedicateDonation = useStateVar<boolean>(false);
  const donationDedicatee = useStateVar<string>("");

  const donationDedicationType = useStateVar<"in_honor_of" | "in_memory_of">("in_honor_of");
  const donationDedicationNotifyType = useStateVar<"none" | "email" | "mail">("none");
  const donationDedicationNotifyMessage = useStateVar<string>("");
  const donationDedicationNotifyRecipient =
    useStateVar<DedicationNotificationRecipient | null>(null);

  const isCardValidationError = useStateVar(false);

  useEffect(() => {
    if (props.formConfig.form_rule_current !== undefined) {
      setFormRule(props.formConfig.form_rule_current);
    }
  }, [props.formConfig.form_rule_current]);

  useEffect(() => {
    if (props.formConfig.step_current !== undefined) {
      setFormStep(props.formConfig.step_current);
    }
  }, [props.formConfig.step_current]);

  function isSpendingRule() {
    return formRule.rule.type === "SPENDING";
  }

  function getSpendingRule() {
    return formConfig.rules.find(rule => rule.rule.type === "SPENDING");
  }

  function isPersonalRule() {
    // impact_based is deprecated, but we include it here since it still exists on the backend.
    return ["event_based", "impact_based", "spending"].includes(formRule.rule.kind);
  }

  /* eslint-disable object-shorthand */
  return {
    formStep: formStep,
    setFormStep: setFormStep,

    isCardValidationError: isCardValidationError,

    isDonationNonPublic: isDonationNonPublic,
    isCoveringFees: isCoveringFees,
    feesCoveringPercentage: feesCoveringPercentage,
    isEmailSubscribe: isEmailSubscribe,
    isDonatingOnBehalfOf: isDonatingOnBehalfOf,
    donationOnBehalfOf: donationOnBehalfOf,
    isDedicateDonation: isDedicateDonation,
    donationDedicatee: donationDedicatee,

    donationDedicationType: donationDedicationType,
    donationDedicationNotifyType: donationDedicationNotifyType,
    donationDedicationNotifyMessage: donationDedicationNotifyMessage,
    donationDedicationNotifyRecipient: donationDedicationNotifyRecipient,

    formRule: formRule,
    setFormRule: setFormRule,

    amount: amount,
    setAmount: setAmount,

    limit: limit,
    setLimit: setLimit,

    tip: tip,
    setTip: setTip,

    earmarking: earmarking,
    setEarmarking: setEarmarking,

    paymentMethod: paymentMethod,
    setPaymentMethod: setPaymentMethod,
    isPlaidPay: () => paymentMethod === PaymentMethod.Plaid,
    isCardPay: () => paymentMethod === PaymentMethod.CreditCard,
    isPaymentReqPay: () => paymentMethod === PaymentMethod.PaymentRequest,
    isStripePay: () => paymentMethod === PaymentMethod.CreditCard ||
      paymentMethod === PaymentMethod.PaymentRequest,

    plaidDetailsRequest: plaidDetailsRequest,
    setPlaidDetailsRequest: setPlaidDetailsRequest,
    plaidAccountId: plaidAccountId,
    setPlaidAccountId: setPlaidAccountId,
    plaidAccountsAvailable: plaidAccountsAvailable,
    setPlaidAccountsAvailable: setPlaidAccountsAvailable,

    isSpendingRule: isSpendingRule,
    getSpendingRule: getSpendingRule,
    isPercentBasedRule: () => isSpendingRule() && getSpendingRule()!.rule_spending_method === "percent",
    isRoundUpBasedRule: () => isSpendingRule() && getSpendingRule()!.rule_spending_method === "round_up",
    isPersonalRule: isPersonalRule,

    stripeToken: stripeToken,
    setStripeToken: setStripeToken,
    stripeCardInfo: stripeCardInfo,
    setStripeCardInfo: setStripeCardInfo,

    isPlaidConnected: () => plaidDetailsRequest !== null,
    isPlaidChargeable: () => plaidAccountsAvailable?.length !== 0,
    isStripeConnected: () => stripeToken !== null,

    userData: userData,
    setUserData: setUserData,
  };
}

export const useFormControl = () => useContext(FormControlContext);

export function FormControlComponent() {
  const form = useFormControl();

  return (
    <Box>
      <Elements
        stripe={getStripe()}
        options={{
          fonts: [
            {
              cssSrc:
                "https://fonts.googleapis.com/css2?family=Mulish:wght@400;600&display=swap",
            },
          ],
        }}
      >
        {form.formStep === FormStep.Step1Rule && <Step1Rule />}
        {form.formStep === FormStep.Step2PaymentConfig && <Step2Payment />}
        {form.formStep === FormStep.Step3UserInfo && <Step3UserInfo />}
        {form.formStep === FormStep.Step4Review && <Step4Review />}
        {form.formStep === FormStep.Step5Success && <Step5Success />}
      </Elements>
    </Box>
  );
}

export interface FixedPlaidLinkOnSuccessMetadata extends PlaidLinkOnSuccessMetadata {
  public_token: string;
}
