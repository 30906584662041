import { useEffect, useState } from "react";

export function useIsWidgetMode(): boolean {
  const [isWidgetMode, setIsWidgetMode] = useState(false);
  useEffect(() => {
    const isLoadedInIframe = window !== window.parent;
    setIsWidgetMode(isLoadedInIframe);
  }, []);

  return isWidgetMode;
}
