import { Box, Flex, Progress, Text, useTheme } from "@chakra-ui/react";
import { Theme } from "@chakra-ui/theme";

export function ProgressBar(props: {
  raisedNum: number;
  raisedLabelPostfix: string;
  raisedOffset: number | null;
  target: number | null;
  mt?: number;
}) {
  const theme: Theme | any = useTheme();
  const raisedNumRounded = Math.round(props.raisedNum + (props.raisedOffset || 0));

  const raisedPercentage = Number((raisedNumRounded / props.target!).toPrecision(2));
  const raisedPercentageArg = raisedPercentage * 100;
  return (
    <Box mt={props.mt}>
      <Flex justifyContent="space-between" mb={theme.space.sm}>
        <Text textStyle="label" fontWeight="semibold" mt={0}>
          {formatDollars(raisedNumRounded)} {props.raisedLabelPostfix}
        </Text>
        <Text textStyle="label" fontWeight="semibold" textAlign="end" mt={0}>
          {formatDollars(formatTarget(props.target!))}
        </Text>
      </Flex>
      <Progress
        role="progressbar"
        aria-valuenow={raisedPercentageArg}
        colorScheme="brand"
        height="20px"
        value={raisedPercentageArg}
        borderRadius="10"
      />
    </Box>
  );
}

function formatTarget(target: number): string {
  if (target >= 1_000_000) {
    return `${target / 1_000_000}m`;
  }
  return String(target);
}

function formatDollars(number: number | string): string {
  return `$${new Intl.NumberFormat("en-US").format(Number(number))}`;
}
