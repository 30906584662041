import LogRocket from "logrocket";
import { deviceDetect } from "react-device-detect";
import { Rule, RuleKind, DonationFormConfig } from "donation-form/src/utils/api-cms";
import {
  FormControlContextI,
  FormStep,
} from "donation-form/src/components/form/useFormControl";
import { UserData } from "donation-form/src/utils/api-create-pledge";

export namespace tracker {
  export interface OrderProps {
    acquisitionSource: string;
    charityName: string;
    rule: Rule;
    ruleAmount: number;
    limit?: number | null;
  }

  export interface OrderCheckoutProps extends OrderProps {
    step: number;
    payment_method?: "card" | "pii" | string;
  }

  export const checkoutStarted = (props: OrderCheckoutProps) => {
    gTrack("event", "begin_checkout", getGoogleAnalyticsOrderData(props));
    segmentTrack("Checkout Started", props);
  };

  export interface CheckoutStep {
    step: FormStep;
    payment_method?: string; // card | pii
    checkout_id?: string;
    form: FormControlContextI;
    config?: DonationFormConfig;
    userData?: UserData;
  }

  export function trackFormStep(
    checkoutProps: tracker.CheckoutStep,
    formConfig: DonationFormConfig,
    form: FormControlContextI,
  ) {
    tracker.group({
      id: formConfig.charity.id,
      name: formConfig.charity.name,
    });
    tracker.checkoutStarted({
      ...checkoutProps,
      acquisitionSource: form.formRule.acquisition_source,
      charityName: formConfig.charity.name,
      rule: form.formRule.rule,
      ruleAmount: form.amount!,
      limit: form.limit,
    });
    tracker.checkoutStepViewed(checkoutProps);
  }

  export const checkoutStepViewed = (props: CheckoutStep) => {
    segmentTrack("Checkout Step Viewed", props);
  };

  export const checkoutStepSubmitted = (props: CheckoutStep) => {
    if (props.form?.userData || props.userData) {
      const user = props.form?.userData ?? props.userData!;
      const extraInfo: any = {
        amount: props.form.amount,
        limit: props.form.limit,
        tip: props.form.tip,
        earmarking: props.form.earmarking,
      };
      if (props.config) {
        extraInfo.charity = props.config.charity.name;
      }

      LogRocket.identify(user.email, {
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        paymentMethod: String(props.payment_method),
        host: window.location.host,
        ...extraInfo,
      });
    }
    gTrack("event", "checkout_submitted");
    segmentTrack("Checkout Step Submitted", props);
  };

  export const checkoutStepCompleted = (props: CheckoutStep) => {
    gTrack("event", "checkout_completed");
    segmentTrack("Checkout Step Completed", props);
  };

  export const paymentInfoEntered = (props: {
    step: number;
    payment_method: "card" | "pii" | string; // card | pii
    payment_brand?: "Visa" | string;
    checkout_id?: string;
    order_id?: string;
  }) => {
    gTrack("event", "add_payment_info", {
      payment_type: props.payment_method,
    });
    segmentTrack("Payment Info Entered", props);
  };

  export function orderCompleted(props: OrderProps) {
    gTrack("event", "purchase", getGoogleAnalyticsOrderData(props));

    segmentTrack("Order Completed", {
      affiliation: props.charityName,
      revenue: props.ruleAmount,
      limit: props.limit,
      currency: "USD",
      products: [
        {
          product_id: props.acquisitionSource,
          category: props.rule.kind,
          price: props.ruleAmount,
          quantity: 1,
        },
      ],
    } as SegmentOrderCompleted);
  }

  interface SegmentOrderCompleted {
    affiliation: string; // charity name
    revenue: number;
    limit?: number;
    currency: "USD";
    products: Array<{
      product_id: string; // acquisition_source name
      category: string; // kind?
      price: number;
      quantity: 1;
    }>;
    checkout_id?: string;
    order_id?: string;
  }

  export function ruleChanged(
    propName: "kind" | "amount" | "limit" | "tip" | "earmarking",
    propValue: RuleKind | number | null | undefined,
  ) {
    const eventName = `rule_${propName}_changed`;
    const props = {};
    props[`rule_${propName}`] = propValue;
    segmentTrack(eventName, props);
    gTrack("event", eventName, props);
  }

  export function identify(
    user?: { id?: number; email: string; firstName?: string; lastName?: string },
    traitsAdditional: any = {},
  ) {
    const gclid = new URL(window.location.href).searchParams.get("gclid");
    const traits = { ...traitsAdditional, ...getDeviceInfo(), gclid };

    if (user) {
      window?.analytics?.identify(user.id, { email: user.email, ...traits });
    }
    window?.analytics?.identify(traits);
  }

  export const alias = (userId: number) => window?.analytics?.alias(userId as any as string);

  export const page = (pageName: string) => window?.analytics?.page(pageName);

  export function group(props: { id: string | number; name: string }) {
    window?.analytics?.group(props.id as string, {
      name: props.name,
    });
  }

  function segmentTrack(event: SegmentEvent, properties = {}, context: any = {}) {
    window?.analytics?.track(event, properties, {
      context,
    });
  }

  function gTrack(
    type: "event" | string,
    event: "purchase" | "begin_checkout" | string,
    data?: Object,
  ) {
    if (window.gtag) {
      window.gtag(type, event, data);
    }
  }
}

function getDeviceInfo(): { deviceType: string; device: string } {
  const deviceInfo = deviceDetect();
  return {
    deviceType: deviceInfo.isBrowser ? "Desktop" : deviceInfo.isMobile ?? deviceInfo.os,
    device: deviceInfo.isBrowser
      ? `${deviceInfo.browserName} ${deviceInfo.browserFullVersion} on ${deviceInfo.osName}`
      : deviceInfo.isMobile ?? `${deviceInfo.vendor} ${deviceInfo.model}`,
  };
}

function getGoogleAnalyticsOrderData(props: tracker.OrderProps): any {
  return {
    affiliation: props.charityName,
    currency: "USD",
    value: props.ruleAmount,
    limit: props.limit, // doesn't seem to be accepted by GA
    items: [
      {
        item_id: props.rule.id,
        item_name: props.acquisitionSource,
        item_category: props.rule.kind,
        price: props.ruleAmount,
        quantity: 1,
      },
    ],
  };
}

type SegmentEvent =
  | "Checkout Started"
  | "Checkout Step Viewed"
  | "Checkout Step Submitted"
  | "Checkout Step Completed"
  | "Payment Info Entered"
  | "Order Completed"
  | "Product Shared"
  | string;

interface SegmentUser {
  id: string | number;
  firstName: string;
  lastName: string;
  email: string;
  address: {
    city: string;
    country: string;
    postalCode: string;
    state: string;
    street: string;
  };
  createdAt: string; // ISO-8601
}

interface SegmentProductShared {
  share_via: "email" | "facebook" | "twitter";
  product_id: string;
  url: string;
}
