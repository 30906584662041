import { Alert, AlertIcon, Collapse, useTheme } from "@chakra-ui/react";

export function PaymentFormError(props: {
  isVisible?: boolean;
  mt?: number;
  children: any;
}) {
  const theme = useTheme();
  return (
    <Collapse in={props.isVisible ?? true} animateOpacity>
      <Alert status="error" mt={props.mt ?? theme.space.md}>
        <AlertIcon />
        {props.children}
      </Alert>
    </Collapse>
  );
}
