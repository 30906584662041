import { extendTheme, theme, withDefaultColorScheme } from "@chakra-ui/react";
import { Theme } from "@chakra-ui/theme";
import { StyleConfig } from "@chakra-ui/theme-tools";
import { DonationFormConfig } from "donation-form/src/utils/api-cms";
import { TinyColor } from "@ctrl/tinycolor";

const colorScheme = "brand";
export const cssVarPrefix = "form";

export const generatePalette = (colorRaw: string) => {
  const colorObj = new TinyColor(colorRaw);
  return {
    25: colorObj.tint(95).toHexString(),
    50: colorObj.tint(90).toHexString(),
    75: colorObj.tint(85).toHexString(),
    100: colorObj.tint(80).toHexString(),
    200: colorObj.tint(60).toHexString(),
    300: colorObj.tint(50).toHexString(),
    400: colorObj.tint(20).toHexString(),
    500: colorRaw,
    600: colorObj.shade(18).saturate(9).toHexString(),
    700: colorObj.shade(29).toHexString(),
    800: colorObj.shade(60).toHexString(),
    900: colorObj.shade(80).toHexString(),
    dark: "#202020",
    momentumPrimary: "#2B3070",
  };
};

export const donationFormThemeBase: Theme | any = {
  sizes: {
    container: {
      lg: "850px",
    },
  },
  config: {
    colorScheme,
    cssVarPrefix,
  },
  space: {
    sm: 2,
    md: 5,
    lg: 7,
    xl: 8,
    xl2: 10,
  },
  fonts: {
    heading: "Mulish, Arial",
    body: "Mulish, Arial",
  },
  textStyles: {
    h1: {
      fontSize: ["1.9rem", null, "2.3rem"],
      fontWeight: "bold",
      lineHeight: 1,
    },
    h2: {
      fontSize: ["1.4rem", null, "1.6rem"],
      fontWeight: "bold",
      mt: 8,
    },
    h3: {
      fontSize: ["1.2rem", null, "1.35rem"],
      fontWeight: "bold",
      lineHeight: 1,
      mt: 8,
    },
    label: {
      fontSize: ["1rem", null, "1.125rem"],
      fontWeight: "normal",
      lineHeight: 1,
      mt: 8,
    },
  },
  styles: {
    global: {
      ".grecaptcha-badge": {
        display: "none !important",
      },
      body: {
        bg: "gray.400",
        transitionDuration: 0,
      },
    },
  },
};

export const donationFormTheme: any | Theme = extendTheme(
  donationFormThemeBase,
  withDefaultColorScheme({ colorScheme }),
);

export type FormTheme = typeof donationFormThemeBase;

export function createThemeWithColorsAndBgs(context: DonationFormConfig): any | Theme {
  return extendTheme(
    {
      ...donationFormTheme,
      colors: {
        brand: {
          ...generatePalette(context.primary_color),
          colorAccent: context.primary_color,
        },
      },
      fonts: {
        heading: context.font_family ?? "Mulish, Arial",
        body: context.font_family ?? "Mulish, Arial",
      },
      components: {
        Input: {
          defaultProps: {
            colorScheme: "brand",
            focusBorderColor: context.primary_color,
          },
          variants: {
            flushed: {
              field: {
                // because of how stripe input work also need to be updated here:
                // src/components/Step2Payment/StripeInput.tsx
                px: 3,
                h: 12,
              },
            },
          },
        },
        Checkbox: {
          baseStyle: {
            control: {
              _focus: {
                boxShadow: "none",
              },
            },
          },
        },
        Radio: {
          baseStyle: {
            control: {
              _focus: {
                boxShadow: "none",
              },
            },
          },
        },
        Select: {
          defaultProps: {
            colorScheme: "brand",
            focusBorderColor: context.primary_color,
          },
          variants: {
            flushed: {
              field: {
                px: 2,
                h: 12,
              },
            },
          },
        } as StyleConfig,
        Button: {
          sizes: {
            lg: {
              fontSize: "lg",
              px: 12,
              py: 7,
            },
            md2: {
              // todo rename
              fontSize: "lg",
              px: 10,
              py: 3,
            },
          },
          variants: {
            group: props => ({
              ...theme.components.Button.variants.outline(props),
              color: "gray.600",
              _active: {
                ...(theme.components.Button.variants.outline(props) as any)._active,
                bg: "white",
                borderColor: "brand.400",
                color: "brand.600",
              },
            }),
          },
        },
        Table: {
          variants: {
            minimalistic: {
              table: {
                mt: 5,
                border: "2px solid",
                borderLeft: "3px solid",
                borderRight: "3px solid",
                borderColor: "gray.100",
              },
              td: {
                px: 3,
                pt: 3,
                pb: 0,
                "&[data-is-numeric=true]": {
                  textAlign: "right",
                },
              },
              tr: {
                "&:last-of-type": {
                  td: {
                    pb: 3,
                  },
                },
              },
              caption: {
                mt: 0,
                py: 4,
                bg: "gray.100",
                fontSize: "0.9rem",
              },
            } as StyleConfig,
          },
        },
      },
      styles: {
        global: {
          ".grecaptcha-badge": {
            display: "none !important",
          },
          ":host, :root": {
            [`--${cssVarPrefix}-shadows-outline`]: `0 0 0 3px ${context.primary_color}`,
          },
          body: {
            bgPos: "center !important",
            bgAttachment: "fixed !important",
            bgSize: "cover !important",
            bgRepeat: "none",
            bg: ["none", "none", context.background_color ?? "gray.400"],
            bgImage: ["none", "none", getBgImage(context)],
            transitionDuration: 0,
          },
        },
      },
    },
    withDefaultColorScheme({ colorScheme: "brand" }),
  );
}

function getBgImage(form: DonationFormConfig): string {
  if (form.background_color) {
    const bgColor = new TinyColor(form.background_color)
      .setAlpha(form.background_color_opacity ?? 0)
      .toRgbString();
    return form.background_image
      ? `linear-gradient(${bgColor}, ${bgColor}), url(${form.background_image})`
      : "none";
  }
  if (form.background_image) {
    return form.background_image ? `url(${form.background_image})` : "none";
  }
  return "none";
}
