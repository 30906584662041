import {
  Box,
  Center,
  ChakraProvider,
  Container,
  Flex,
  HStack,
  Image,
  Link,
  Spacer,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { Theme } from "@chakra-ui/theme";
import "focus-visible/dist/focus-visible";
import "iframe-resizer/js/iframeResizer.contentWindow.min";

import { GoogleAnalyticsSnippet } from "donation-form/src/components/form/shared/GoogleAnalyticsSnippet";
import { SegmentSnippet } from "donation-form/src/components/form/shared/SegmentSnippet";
import { MomentumLogo } from "donation-form/src/components/form/shared/MomentumLogo";
import { createThemeWithColorsAndBgs } from "donation-form/src/styles/theme";
import { DonationFormConfig } from "donation-form/src/utils/api-cms";
import Head from "next/head";
import { createContext, useContext, useEffect, useState } from "react";
import {
  FormControlComponent,
  FormControlContextProvider,
} from "donation-form/src/components/form/useFormControl";
import { useIsWidgetMode } from "donation-form/src/components/form/useIsWidgetMode";
import { Global } from "@emotion/react";
import { useInit } from "donation-form/src/hooks/useInit";
import { getUrlParam } from "donation-form/src/utils/getUrlParam";

export const DonationFormConfigContext = createContext<DonationFormConfig>({} as any);

export const tipDefault = 15;
export const limitDefault = 50;

export function DonationFormPageComponent(props: {
  formConfig: DonationFormConfig;
  theme?: Theme | any;
}) {
  const isWidgetMode = useIsWidgetMode();
  const [theme, setTheme] = useState(
    props.theme ?? createThemeWithColorsAndBgs(props.formConfig),
  );
  const [formBgColor, setFormBgColor] = useState("white");
  const [fontUrl, setFontUrl] = useState<string | null>(props.formConfig.font_url ?? null);
  const [customCssUrl, setCustomCssUrl] = useState<string | null>(null);

  const comp = {
    isShowHeadMetadata: !props.formConfig.is_preview_mode,
    isRenderAnalytics: !props.formConfig.is_preview_mode,
    isRenderHeader: !isWidgetMode && !props.formConfig.is_embedded_mode,
    isRenderTitle: !isWidgetMode && props.formConfig.is_embedded_mode,
    isShowFooter: !isWidgetMode && !props.formConfig.is_hide_footer,
    isShowContainer: !isWidgetMode || getUrlParam("show-container"),
    isFullHeight: !isWidgetMode,
  };

  useInit(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length !== 0) {
      const configNew = { ...props.formConfig };
      configNew.background_color = params["background-color"] ?? configNew.background_color;
      configNew.primary_color = params["primary-color"] ?? configNew.primary_color;
      configNew.font_url = params["font-url"] ?? configNew.font_url;
      configNew.font_family = params["font-family"] ?? configNew.font_family;
      const themeNew = createThemeWithColorsAndBgs(configNew);
      setTheme(themeNew);
      setFontUrl(configNew.font_url);
      setFormBgColor(params["form-background-color"] ?? formBgColor);
      setCustomCssUrl(params["custom-css-url"] ?? null);
    }
  });

  useEffect(() => {
    setTheme(props.theme ?? createThemeWithColorsAndBgs(props.formConfig));
  }, [props.theme]);

  return (
    <DonationFormConfigContext.Provider value={{ ...props.formConfig }}>
      <ChakraProvider theme={theme}>
        {fontUrl && (
          <Global
            styles={`
              @import url("${fontUrl}");
            `}
          />
        )}
        {customCssUrl && (
          <Global
            styles={`
              @import url("${customCssUrl}");
            `}
          />
        )}

        {comp.isShowHeadMetadata && (
          <Head>
            <title>
              {props.formConfig.seo_title
                ? props.formConfig.seo_title
                : `${props.formConfig.title} - ${props.formConfig.charity.name}`}
            </title>
            <meta
              name="description"
              content={props.formConfig.seo_description ?? "Donate now"}
            />
            <link rel="icon" href={props.formConfig.favicon} />
          </Head>
        )}

        {comp.isRenderAnalytics && (
          <Head>
            <SegmentSnippet trackerId={props.formConfig.segment_id} />
            <GoogleAnalyticsSnippet trackerId={props.formConfig.google_analytics_id} />
          </Head>
        )}

        <Head>
          <meta httpEquiv="Access-Control-Allow-Origin" content="*" />
        </Head>

        <Flex minH={comp.isShowFooter ? "100vh" : "100%"} direction="column">
          {comp.isRenderHeader && <Header />}
          {comp.isRenderTitle && (
            <Center>
              <Text as="h1" textStyle="h1" mt={theme.space.xl}>
                {props.formConfig.title}
              </Text>
            </Center>
          )}

          <Box
            my={
              comp.isShowContainer
                ? [isWidgetMode ? theme.space.xl : 0, null, theme.space.xl2]
                : 0
            }
            mt={comp.isShowContainer ? [theme.space.xl, null, theme.space.xl2] : 0}
          >
            <Container
              maxW={comp.isShowContainer ? "container.lg" : "100%"}
              px={comp.isShowContainer ? theme.space.md : 0}
            >
              <Box
                p={
                  comp.isShowContainer
                    ? props.formConfig.is_embedded_mode
                      ? [theme.space.md, null, theme.space.xl]
                      : [0, 0, theme.space.xl]
                    : 0
                }
                bg={formBgColor}
                borderRadius={comp.isShowContainer ? 5 : 0}
              >
                <FormControlContextProvider config={props.formConfig}>
                  <FormControlComponent />
                </FormControlContextProvider>

                {isWidgetMode && (
                  <Center mt={theme.space.lg}>
                    <PoweredByMomentum isSmallSize />
                  </Center>
                )}
              </Box>
            </Container>
          </Box>

          {comp.isShowFooter && <Spacer />}
          {comp.isShowFooter && <Footer />}
        </Flex>
      </ChakraProvider>
    </DonationFormConfigContext.Provider>
  );
}

export const useFormConfig = () => useContext(DonationFormConfigContext);

function Header() {
  const theme = useTheme();
  const configCtx = useFormConfig();

  let headerLogoUrl = "";
  if (
    configCtx.header_logo_url.includes("http://") ||
    configCtx.header_logo_url.includes("https://")
  ) {
    headerLogoUrl = configCtx.header_logo_url;
  } else {
    headerLogoUrl = `https://${configCtx.header_logo_url}`;
  }

  return (
    <Box
      p={theme.space.md}
      bg={
        configCtx.is_dark_mode_enabled_for_header_and_footer
          ? theme.colors.brand.dark
          : "white"
      }
      justifyContent="center"
      d="flex"
      pos="relative"
    >
      <a href={headerLogoUrl}>
        <Image
          src={configCtx.header_logo}
          alt={configCtx.header_logo}
          width={configCtx.header_logo_width}
          objectFit="cover"
          fallback={<Box height={50} width={configCtx.header_logo_width} />}
        />
      </a>
    </Box>
  );
}

function Footer() {
  const theme = useTheme();
  const configCtx = useContext(DonationFormConfigContext);
  return (
    <Box
      bg={
        configCtx.is_dark_mode_enabled_for_header_and_footer
          ? theme.colors.brand.dark
          : "white"
      }
      p={theme.space.xl}
      mt={[theme.space.xl, theme.space.xl, 0]}
    >
      <Flex direction="column" align="center">
        <PoweredByMomentum />

        <Text mt={theme.space.md} fontSize="xs" color="gray.400" textAlign="center">
          Protected by reCAPTCHA,{" "}
          <Link href="https://policies.google.com/privacy" isExternal>
            Privacy
          </Link>{" "}
          and{" "}
          <Link href="https://policies.google.com/terms" isExternal>
            Terms
          </Link>{" "}
          apply
        </Text>
      </Flex>
    </Box>
  );
}

function PoweredByMomentum(props: { isSmallSize?: boolean }) {
  const theme = useTheme();
  const configCtx = useContext(DonationFormConfigContext);

  return (
    <Link isExternal href="https://givemomentum.com/" _hover={{ textDecoration: "none" }}>
      <HStack spacing={0}>
        <Text
          mr={theme.space.sm}
          fontSize={props.isSmallSize ? "0.78rem" : "0.93rem"}
          mt={props.isSmallSize ? "0px" : "2px"}
          fontWeight="bold"
          color={
            configCtx.is_dark_mode_enabled_for_header_and_footer
              ? "white"
              : "rgb(43, 48, 112)"
          }
        >
          Powered by
        </Text>
        <MomentumLogo
          width={props.isSmallSize ? 120 : 128}
          height={props.isSmallSize ? 21 : 25}
          color={configCtx.is_dark_mode_enabled_for_header_and_footer ? "white" : null}
        />
      </HStack>
    </Link>
  );
}
