export function GoogleAnalyticsSnippet(props: {
  trackerId?: string;
  trackerIdMomentum?: string;
}) {
  const tagId = props.trackerId ?? process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
  const tagIdMomentum =
    props.trackerIdMomentum ?? process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID_MOMENTUM;
  if (tagId || tagIdMomentum) {
    const tagIdConfig = tagId && `gtag('config', '${tagId}');`;
    const tagIdMomentumConfig = tagIdMomentum && `gtag('config', '${tagIdMomentum}');`;
    return (
      <>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${tagIdMomentum}`} />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            ${tagIdMomentumConfig}
            ${tagIdConfig}
          `,
          }}
        />
      </>
    );
  }
  return null;
}
