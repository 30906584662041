import { CheckCircleIcon, Icon, LinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Text,
  useClipboard,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { AiOutlineTwitter } from "react-icons/ai";
import { GoMail } from "react-icons/go";
import { TiSocialFacebook } from "react-icons/ti";
import { DonationFormConfigContext } from "donation-form/src/components/form";
import { DonationRuleReview } from "donation-form/src/components/form/shared/DonationRuleReview";

export function Step5Success() {
  const theme = useTheme();
  const configCtx = useContext(DonationFormConfigContext);

  const [originUrl, setOriginUrl] = useState("");
  const clipboard = useClipboard(originUrl);

  useEffect(
    () => setOriginUrl(
      window.location != window.parent.location
        ? document.referrer
        : document.location.href,
    ),
    [],
  );

  return (
    <>
      <VStack align="center">
        <Icon as={CheckCircleIcon} color="green" w={7} h={7} />
        <Text textStyle="h2">Your donation is all set up</Text>
      </VStack>
      <Box pt={theme.space.md}>
        <Text textAlign="center">
          Your donation can start a chain reaction, but only if you tell others about the
          cause. Multiply your impact by sharing this project with friends and family.
        </Text>
      </Box>
      <HStack mt={theme.space.lg} justify="center">
        <ShareIcon
          color="facebook"
          icon={TiSocialFacebook}
          link={`https://www.facebook.com/sharer/sharer.php?u=${originUrl}`}
        />
        <ShareIcon
          color="twitter"
          icon={AiOutlineTwitter}
          link={`https://twitter.com/intent/tweet?url=${originUrl}&text=`}
        />
        <ShareIcon
          color="gray"
          icon={GoMail}
          link={`mailto:?subject=${encodeURI(
            configCtx.seo_title ?? configCtx.title,
          )}&amp;body=${encodeURI(configCtx.seo_description ?? configCtx.description)}.`}
        />
      </HStack>

      <Center mt={theme.space.lg}>
        <InputGroup size="md" maxW="400px">
          <InputLeftElement pointerEvents="none" children={<LinkIcon color="gray.400" />} />
          <Input value={originUrl} readOnly />
          <InputRightElement w={clipboard.hasCopied ? "5.3rem" : "4.5rem"}>
            <Button h={7} size="sm" onClick={clipboard.onCopy}>
              {clipboard.hasCopied ? "Copied!" : "Copy"}
            </Button>
          </InputRightElement>
        </InputGroup>
      </Center>

      <Divider mt={theme.space.xl2} />

      <DonationRuleReview
        textStyle="h3"
        isShowTip={configCtx.is_enable_tip}
        isTitleCentered
        title="Donation Summary"
      />
    </>
  );
}

export function ShareIcon(props: { icon: any; color: string; link: string }) {
  const theme = useTheme();
  const shareIconSize = 8;
  const shareIconBox = "45px";
  return (
    <Link href={props.link} isExternal>
      <Center
        w={shareIconBox}
        h={shareIconBox}
        bg={`${props.color}.500`}
        mx={theme.space.sm}
      >
        <Icon as={props.icon} w={shareIconSize} h={shareIconSize} color="white" />
      </Center>
    </Link>
  );
}
